import { NavLink, useLocation } from 'react-router-dom'
import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import './OpenSideMenu.scss'
import { motion } from 'framer-motion'
import useOutsideClick from '@hooks/useOutsideClick'
import SpatialNavigation from '@utils/spatial-navigation.js'
import { focusableRoutes, mainRoutes, routes } from '@constants/routes.const'
import { setMenuIcon } from '../menuIcons'
import Image from '@components/Image/Image'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

export interface menuItem {
  text: string
  path: string
  icon: string
}

export interface OpenSideMenuProps {
  setOpenMenu: (arg0: boolean) => void
  menuItems?: menuItem[]
}
export const OpenSideMenu = ({ setOpenMenu, menuItems }: OpenSideMenuProps) => {
  const [userName, setUserName] = useState<string | null>('')
  const ref = useRef<HTMLAnchorElement>(null)
  const location = useLocation()

  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const userNameFromLS = userObject?.displayName
  const userPhotoFromLS = userObject?.photoURL
  const userEmailFromLS = userObject?.email
  const userDisplayName = userName || userEmailFromLS?.split('@')[0]

  const SN = SpatialNavigation

  const userPhoto = useMemo(
    () =>
      userPhotoFromLS ? (
        <Image image={userPhotoFromLS} alt="User" width="58" height="58" />
      ) : (
        <div className="open-side-menu-profile-image-letter">
          {userName?.charAt(0) || userEmailFromLS?.charAt(0).toUpperCase()}
        </div>
      ),
    [userEmailFromLS, userName, userPhotoFromLS],
  )

  useEffect(() => {
    if (userNameFromLS !== null) {
      setUserName(userNameFromLS)
    }
  }, [userNameFromLS])

  useEffect(() => {
    ref.current ? ref.current.focus() : SN.focus()
  }, [SN])

  const closeMenuOnClick = useCallback(
    (pathUrl: string) => {
      setOpenMenu(false)

      pathUrl.includes(mainRoutes.HOME) && sessionStorage.removeItem('lastFocusedCard-home')
      pathUrl === routes.MY_LIST && sessionStorage.removeItem('lastFocusedCard-myList')
    },
    [setOpenMenu],
  )

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false)
    SN.focus('app-content')
  }, [SN, setOpenMenu])

  const menuRef = useOutsideClick<HTMLDivElement>(handleCloseMenu)

  return (
    <div className="open-side-menu-container">
      <motion.div
        className="open-side-menu"
        initial={{ x: '-120px' }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', bounce: 0, duration: 0.8 }}
        ref={menuRef}
      >
        <div className="open-side-menu-user">
          <NavLink
            to={routes.PROFILE}
            id="profile"
            data-sn-down={`#${focusableRoutes.MENU}-item-0`}
            data-sn-up=""
            tabIndex={-1}
            onClick={() => closeMenuOnClick(routes.PROFILE)}
            className={({ isActive }) =>
              `${isActive && location.pathname === routes.PROFILE ? 'active ' : ''}open-side-menu-profile focusable`
            }
            ref={location.pathname === routes.PROFILE ? ref : null}
          >
            <div className="open-side-menu-profile-image">{userPhoto}</div>
            <Text tag={TextTag.SPAN} className="open-side-menu-profile-name">
              {userDisplayName}
            </Text>
          </NavLink>
        </div>
        <div className="open-side-menu-navigation">
          {menuItems?.map(({ path, icon, text }, idx) => {
            return (
              <NavLink
                to={`${path}`}
                key={`${focusableRoutes.MENU}-item-${idx}`}
                id={`${focusableRoutes.MENU}-item-${idx}`}
                data-sn-down={`#${focusableRoutes.MENU}-item-${idx + 1}`}
                data-sn-up={idx === 0 ? `#${focusableRoutes.PROFILE}` : `#${focusableRoutes.MENU}-item-${idx - 1}`}
                tabIndex={-1}
                className={({ isActive }) => `${isActive && location.pathname === path ? 'active ' : ''}focusable`}
                onClick={() => closeMenuOnClick(path)}
                ref={location.pathname === path ? ref : null}
              >
                <div className="open-side-menu-wrapper">
                  <div className="open-side-menu-item">
                    {setMenuIcon(icon)}
                    <Text tag={TextTag.P} className="open-side-menu-text">
                      {text}
                    </Text>
                  </div>
                  <div className="side-menu-border"></div>
                </div>
              </NavLink>
            )
          })}
        </div>
      </motion.div>
    </div>
  )
}
