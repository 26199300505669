import React, { RefObject, useCallback } from 'react'
import './InitialScreenContent.scss'
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton'
import { useNavigate } from 'react-router-dom'
import Title from '@components/Title/Title'
import { routes } from '@constants/routes.const'

interface InitialScreenContentProps {
  focusedBtn: RefObject<HTMLButtonElement>
}

const InitialScreenContent = ({ focusedBtn }: InitialScreenContentProps) => {
  const navigate = useNavigate()

  const navigateToLoginPage = useCallback(() => {
    navigate(routes.LOGIN)
  }, [navigate])

  const navigateToRegisterPage = useCallback(() => {
    navigate(routes.REGISTER)
  }, [navigate])

  return (
    <div className="initial-screen-content">
      <div className="text">
        <Title title="Millions of videos to have a good time" />
        <Title title="Choose plan that suits your needs and enjoy!" />
      </div>
      <div className="buttons">
        <SecondaryButton onClick={navigateToLoginPage} buttonRef={focusedBtn}>
          Login
        </SecondaryButton>
        <SecondaryButton onClick={navigateToRegisterPage}>Register</SecondaryButton>
      </div>
    </div>
  )
}

export default InitialScreenContent
