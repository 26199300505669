import React from 'react'
import heroBg3 from '@assets/images/herofullwidth-bg3.png'
import { RealMadrid } from '@assets/index'
import classNames from 'classnames'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { DocumentData } from 'firebase/firestore'

export const renderSimpleSportsCard = (item: CardProps | RailDetailsItem | DocumentData, isActive?: boolean) => {
  return (
    <div className="row-4-sports-card">
      <div
        className={classNames('row-4-sports-card__focus', {
          'row-4-sports-card__focused': isActive,
        })}
      ></div>
      <img src={heroBg3} alt="card" />
      <div className="row-4-sports-card-local">
        <RealMadrid className="row-4-sports-team-badge" />
        <p className="row-4-sports-card-team-name">{item.firstTeam}</p>
      </div>
      <div className="row-4-sports-card-vs">VS</div>
      <div className="row-4-sports-card-visitor">
        <RealMadrid className="row-4-sports-team-badge" />
        <p className="row-4-sports-card-team-name">{item.secondTeam}</p>
      </div>
    </div>
  )
}

export const renderScheduleSportsCard = (item: CardProps | RailDetailsItem | DocumentData, isActive?: boolean) => {
  return (
    <div className="row-4-sports-card">
      <div
        className={classNames('row-4-sports-card__focus', {
          'row-4-sports-card__focused': isActive,
        })}
      ></div>
      <img src={heroBg3} alt="card" />
      <div className="row-4-sports-card-schedule">
        <p className="row-4-sports-card-schedule-date">{item.date}</p>
        <p className="row-4-sports-card-schedule-time">{item.time}</p>
      </div>
      <div className="row-4-sports-card-local schedule">
        <RealMadrid className="row-4-sports-team-badge" />
        <p className="row-4-sports-card-team-name">{item.firstTeam}</p>
      </div>
      <div className="row-4-sports-card-vs schedule">VS</div>
      <div className="row-4-sports-card-visitor schedule">
        <RealMadrid className="row-4-sports-team-badge " />
        <p className="row-4-sports-card-team-name">{item.secondTeam}</p>
      </div>
    </div>
  )
}

export const renderLiveSportsCard = (item: CardProps | RailDetailsItem | DocumentData, isActive?: boolean) => {
  return (
    <div className="row-4-sports-card">
      <div
        className={classNames('row-4-sports-card__focus', {
          'row-4-sports-card__focused': isActive,
        })}
      ></div>
      <img src={heroBg3} alt="card" />
      <div className="row-4-sports-card-local live">
        <RealMadrid className="row-4-sports-team-badge" />
        <p className="row-4-sports-card-team-name">{item.firstTeam}</p>
      </div>
      <div className="row-4-sports-card-score live">
        <p className="row-4-sports-card-score-text">{item.liveResult}</p>
      </div>
      <div className="row-4-sports-card-visitor live">
        <RealMadrid className="row-4-sports-team-badge" />
        <p className="row-4-sports-card-team-name">{item.secondTeam}</p>
      </div>
      <p className="row-4-sports-card-live">LIVE</p>
    </div>
  )
}

export const renderCountdownSportsCard = (item: CardProps | RailDetailsItem | DocumentData, isActive?: boolean) => {
  return (
    <div className="row-4-sports-card">
      <div
        className={classNames('row-4-sports-card__focus', {
          'row-4-sports-card__focused': isActive,
        })}
      ></div>
      <img src={heroBg3} alt="card" />
      <div className="row-4-sports-card-countdown">
        <p className="row-4-sports-card-countdown-date">{item.date}</p>
        <p className="row-4-sports-card-countdown-time">{item.time}</p>
      </div>
      <div className="row-4-sports-card-local-countdown countdown">
        <RealMadrid className="row-4-sports-team-badge-countdown" />
        <p className="row-4-sports-card-team-name">{item.firstTeam}</p>
      </div>
      <div className="row-4-sports-card-vs-countdown countdown">VS</div>
      <div className="row-4-sports-card-visitor-countdown countdown">
        <RealMadrid className="row-4-sports-team-badge-countdown" />
        <p className="row-4-sports-card-team-name">{item.secondTeam}</p>
      </div>
      <div className="row-4-sports-card-countdown-box">
        <div className="row-4-sports-card-countdown-box-time">
          <div className="countdown-number">
            <p>28</p>
            <p className="countdown-colon">:</p>
          </div>
          <p className="countdown-calendar days">Days</p>
        </div>
        <div className="row-4-sports-card-countdown-box-time">
          <div className="countdown-number">
            <p>24</p>
            <p className="countdown-colon">:</p>
          </div>
          <p className="countdown-calendar">Hours</p>
        </div>
        <div className="row-4-sports-card-countdown-box-time">
          <div className="countdown-number">
            <p>43</p>
            <p className="countdown-colon">:</p>
          </div>
          <p className="countdown-calendar">Minutes</p>
        </div>
        <div className="row-4-sports-card-countdown-box-time">
          <div className="countdown-number">
            <p>43</p>
          </div>
          <p className="countdown-calendar">Seconds</p>
        </div>
      </div>
    </div>
  )
}
