import Text from '@components/Text/Text'
import './ProfileTitle.scss'
import { TextTag } from '@enums/textTag'

interface ProfileTitleProps {
  title: string
}

export const ProfileTitle = ({ title }: ProfileTitleProps) => {
  return (
    <Text tag={TextTag.P} className="profile-title">
      {title}
    </Text>
  )
}
