import { Dislike, InfoIcon, Like, Play, Plus, Tick, X } from '@assets/index'

export const setIcon = (icon: string) => {
  switch (icon) {
    case 'PLUS':
      return <Plus />
    case 'PLAY':
      return <Play />
    case 'LIKE':
      return <Like />
    case 'DISLIKE':
      return <Dislike />
    case 'X':
      return <X />
    case 'TICK':
      return <Tick />
    case 'INFO':
      return <InfoIcon />
    default:
      return <Plus />
  }
}
