import React from 'react'
import './SynopsisPrimarySkeleton.scss'

const SynopsisPrimarySkeleton = () => {
  return (
    <div className="synopsis-primary-skeleton-wrapper">
      <div className="skeleton-wrapper-left">
        <div className="skeleton-element details-content"></div>
        <div className="skeleton-element details-content-short"></div>
        <div className="skeleton-element details-content"></div>
        <div className="skeleton-element details-content-short"></div>
      </div>
      <div className="skeleton-wrapper-middle">
        <div className="skeleton-element details-content"></div>
        <div className="skeleton-element details-content-short"></div>
        <div className="skeleton-element details-content"></div>
        <div className="skeleton-element details-content-short"></div>
      </div>
      <div className="skeleton-wrapper-right">
        <div className="skeleton-element details-content"></div>
        <div className="skeleton-element details-content-big-2"></div>
        <div className="skeleton-element details-content-big-3"></div>
        <div className="skeleton-element details-content-big-1"></div>
      </div>
    </div>
  )
}

export default SynopsisPrimarySkeleton
