import './HeroSkeleton.scss'
interface HeroSkeletonProps {
  page: 'home' | 'details'
}

const HeroSkeleton = ({ page }: HeroSkeletonProps) => {
  return (
    <div className="hero-skeleton-wrapper">
      <div className="hero-skeleton-elements-wrapper">
        <div className="skeleton-element hero-skeleton-image-title"></div>
        <div className="skeleton-element hero-skeleton-text"></div>
        <div className="hero-skeleton-button-container">
          <div className="skeleton-element hero-skeleton-button-large"></div>
          <div className="skeleton-element hero-skeleton-button-small"></div>
          {page === 'home' && <div className="skeleton-element hero-skeleton-button-small"></div>}
        </div>
      </div>
    </div>
  )
}

export default HeroSkeleton
