import { Auth, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithCustomToken } from '@firebase/auth'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { fetchToApi } from '@services/api'
import { fetchMethods } from '@constants/api.const'
import { AuthResponse } from '@interfaces/auth.interface'

export const sendResetPasswordEmail = async (firebaseAuth: Auth, userEmail: string) => {
  await sendPasswordResetEmail(firebaseAuth, userEmail)
}

export const login = async (firebaseAuth: Auth, userEmail: string, userPassword: string) => {
  const response = await signInWithEmailAndPassword(firebaseAuth, userEmail, userPassword)
  let accessToken = ''
  const accessTokenProp = 'accessToken'
  if (Object.prototype.hasOwnProperty.call(response.user, accessTokenProp)) {
    accessToken = response.user[accessTokenProp as keyof typeof response.user] as string
  }
  const { email, uid } = response.user
  return await fetchToApi('login', fetchMethods.POST, undefined, { accessToken, email, uid }, accessToken, true)
    .then(({ token, refreshToken, user }) => {
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('userInfo', JSON.stringify(auth.currentUser))
      localStorage.setItem('userToken', token)
      localStorage.setItem('refreshToken', refreshToken)
      return user
    })
    .catch((err) => err)
}

export const logout = async (firebaseAuth: Auth) => {
  return await signOut(firebaseAuth)
    .then((response) => {
      localStorage.removeItem('user')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userToken')
      localStorage.removeItem('refreshToken')
      return response
    })
    .catch((err) => err)
}

export const loginWithCustomToken = async (token: string) => {
  const response = await signInWithCustomToken(auth, token)
  let accessToken = ''
  const accessTokenProp = 'accessToken'
  if (Object.prototype.hasOwnProperty.call(response.user, accessTokenProp)) {
    accessToken = response.user[accessTokenProp as keyof typeof response.user] as string
  }
  const {
    user: { email, uid },
  } = response
  return await fetchToApi('login', fetchMethods.POST, undefined, { accessToken, email, uid }, accessToken, true)
    .then(({ user, token, refreshToken }) => {
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('userInfo', JSON.stringify(auth.currentUser))
      localStorage.setItem('userToken', token)
      localStorage.setItem('refreshToken', refreshToken)
      return user
    })
    .catch((err) => err)
}

/**
 * Check if current token is valid
 * @param apiToken
 * @returns Promise<AuthResponse>
 */
export const checkApiToken = async (apiToken: string): Promise<AuthResponse> => {
  return await fetchToApi('token/check', fetchMethods.GET, undefined, undefined, apiToken, true)
    .then((apiResponse) => apiResponse)
    .catch((err) => err)
}

/**
 * Update current tokens using current refresh token
 * @param refreshToken
 * @returns Promise<AuthResponse>
 */
export const handleRefreshToken = async (refreshToken: string): Promise<AuthResponse> => {
  return await fetchToApi('refresh', fetchMethods.POST, undefined, { refreshToken }, undefined, true)
    .then(({ user, token, refreshToken: newRefreshToken }) => {
      if (user && newRefreshToken && token) {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('userToken', token)
        localStorage.setItem('refreshToken', newRefreshToken)
      }
    })
    .catch((err) => err)
}

/**
 * Check if user token is valid, if not, update refresh and user token
 * @param successCallback
 * @param errorCallback
 */
export const checkUser = (successCallback: () => void, errorCallback: () => void): void => {
  const userToken = localStorage.getItem('userToken')
  const refreshToken = localStorage.getItem('refreshToken')

  if (userToken && refreshToken) {
    checkApiToken(userToken)
      .then(({ user }) => {
        if (!user) {
          handleRefreshToken(refreshToken)
            .then(successCallback)
            .catch(errorCallback)
        } else {
          successCallback()
        }
      })
      .catch(errorCallback)
  } else {
    errorCallback()
  }
}
