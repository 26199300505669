import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setProfileButtonFocus } from '@actions/profileActions'
import { onEnterKeyPressAndClick, onBackKeyPress } from '@utils/helpers'
import { ProfileTitle } from '@components/Profile/ProfileTitle/ProfileTitle'
import './ProfileInfo.scss'
import { useTypedSelector } from 'store'
import { ProfileButton } from '../ProfileButton/ProfileButton'
import { profileRoutes, routes } from '@constants/routes.const'
import { language } from '@constants/lang.const'
import Image from '@components/Image/Image'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface ProfileInfoProps {
  showMenu: (status: boolean) => void
}

export const ProfileInfo = ({ showMenu }: ProfileInfoProps) => {
  const V = useTypedSelector((state) => state.vocabulary.data)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nameRef = useRef<HTMLDivElement>(null)
  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const getVocabFromLS = JSON.parse(localStorage.getItem(userObject?.uid) as string)
  const userNameFromLS = userObject?.displayName
  const userEmailFromLS = userObject?.email
  const userPhotoFromLS = userObject?.photoURL
  const navigateToEditLang = `${routes.PROFILE}/${profileRoutes.PROFILE_INFO}/${profileRoutes.EDIT_LANG}`

  const userLanguageValue = () => {
    if (getVocabFromLS === language.EN) {
      return V.vocabularyLanguage_en
    } else if (getVocabFromLS === language.ES) {
      return V.vocabularyLanguage_es
    } else {
      return V.vocabularyLanguage_en
    }
  }

  const closeProfileInfoPage = useCallback(
    (e: KeyboardEvent) =>
      onBackKeyPress(e, () => {
        navigate(-1)
      }),
    [navigate],
  )

  const handleNavigation = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent, route: string) => {
      e.preventDefault()

      onEnterKeyPressAndClick(e, () => navigate(route))
    },
    [navigate],
  )

  useEffect(() => {
    showMenu(false)
    nameRef.current?.focus()
    dispatch(setProfileButtonFocus(V.infoProfileLabel))

    return () => {
      showMenu(true)
    }
  }, [showMenu, dispatch, V.infoProfileLabel])

  useEffect(() => {
    document.addEventListener('keydown', closeProfileInfoPage, false)

    return () => document.removeEventListener('keydown', closeProfileInfoPage, false)
  })

  return (
    <div className="profile-info-container">
      <ProfileTitle title={V.infoProfileLabel} />
      <div className="profile-details">
        <div className="profile-info">
          <ProfileButton
            buttonType={'medium'}
            buttonId={'profileName'}
            buttonText={V.profileNameInfo}
            buttonValue={userNameFromLS || userEmailFromLS?.split('@')[0]}
            buttonRef={nameRef}
          />
          <ProfileButton
            buttonType={'medium'}
            buttonId={'profileLanguage'}
            buttonText={V.editProfileInputLanguageLabel}
            buttonValue={userLanguageValue()}
            onKeyDownEvent={(e) => handleNavigation(e, navigateToEditLang)}
            onClick={(e) => handleNavigation(e, navigateToEditLang)}
          />
        </div>
        <div className="profile-image">
          <div className="profile-image-letter">
            {userPhotoFromLS ? (
              <Image image={userPhotoFromLS} alt="User" width="284" height="284" />
            ) : (
              <div className="open-side-menu-profile-image-letter">
                {userNameFromLS?.charAt(0) || userEmailFromLS?.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
        </div>
      </div>
      <Text tag={TextTag.P} className="profile-edit-hint">
        {V.editProfileLabel}
      </Text>
    </div>
  )
}
