import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './Legal.scss'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setProfileButtonFocus } from '@actions/profileActions'
import { KeyName } from '@constants/index'
import { onBackKeyPress } from '@utils/helpers'
import { ProfileButton } from '@components/Profile/ProfileButton/ProfileButton'
import { ProfileTitle } from '@components/Profile/ProfileTitle/ProfileTitle'
import { policyType } from '@constants/policy.const'
import SubscriberAgreement from './PolicyContent/SubscriberAgreement/SubscriberAgreement'
import CookiesPolicy from './PolicyContent/CookiesPolicy/CookiesPolicy'
import PrivacyPolicy from './PolicyContent/PrivacyPolicy/PrivacyPolicy'
import { useTypedSelector } from 'store'

interface LegalProps {
  showMenu: (status: boolean) => void
}

export const Legal = ({ showMenu }: LegalProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [buttonActive, setButtonActive] = useState('privacy-policy-button')
  const privacyPolicyButtonRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const V = useTypedSelector((state) => state.vocabulary.data)
  const scrollAmount = 50
  const handleButton = (e: React.FocusEvent) => setButtonActive(e.target.id)

  const handleScroll = (e: React.KeyboardEvent) => {
    const scrollCurrent = scrollRef.current
    switch (e.key) {
      case KeyName.ArrowUp:
        scrollCurrent?.scrollBy(0, -scrollAmount)
        break
      case KeyName.ArrowDown:
        scrollCurrent?.scrollBy(0, scrollAmount)
        break
    }
  }

  const closeProfileLegalPage = useCallback((e: KeyboardEvent) => onBackKeyPress(e, () => navigate(-1)), [navigate])

  const handleFocus = useCallback(() => {
    scrollRef.current?.focus()
    const handleWheel = (e: WheelEvent) => scrollRef.current?.scrollBy(0, e.deltaY > 0 ? scrollAmount : -scrollAmount)
    scrollRef.current?.addEventListener('wheel', handleWheel)

    return () => {
      scrollRef.current?.removeEventListener('wheel', handleWheel)
    }
  }, [])

  useEffect(() => {
    showMenu(false)
    privacyPolicyButtonRef?.current?.focus()
    dispatch(setProfileButtonFocus(V.profilePreferencesLegalTitle))

    return () => {
      showMenu(true)
    }
  }, [showMenu, dispatch, V.profilePreferencesLegalTitle])

  useEffect(() => {
    document.addEventListener('keydown', closeProfileLegalPage, false)

    return () => document.removeEventListener('keydown', closeProfileLegalPage, false)
  })

  const renderPolicy = useMemo(() => {
    switch (buttonActive) {
      case policyType.PRIVACY:
        return (
          <PrivacyPolicy
            scrollRef={scrollRef}
            onMouseEnter={handleFocus}
            onKeyDown={handleScroll}
            focusOnLeft={`#${buttonActive}`}
          />
        )
      case policyType.COOKIES:
        return (
          <CookiesPolicy
            scrollRef={scrollRef}
            onMouseEnter={handleFocus}
            onKeyDown={handleScroll}
            focusOnLeft={`#${buttonActive}`}
          />
        )
      case policyType.SUBSCRIBER:
        return (
          <SubscriberAgreement
            scrollRef={scrollRef}
            onMouseEnter={handleFocus}
            onKeyDown={handleScroll}
            focusOnLeft={`#${buttonActive}`}
          />
        )
    }
  }, [buttonActive, handleFocus])

  return (
    <div className="legal-container">
      <div className="section-left">
        <ProfileTitle title={V.profilePreferencesLegalTitle} />
        <div className="legal-content-buttons">
          <ProfileButton
            buttonType="small"
            buttonId={policyType.PRIVACY}
            buttonText={V.legalSectionPrivacyPolicyLabel}
            onFocusEvent={handleButton}
            buttonRef={privacyPolicyButtonRef}
          />
          <ProfileButton
            buttonType="small"
            buttonId={policyType.COOKIES}
            buttonText={V.legalSectionCookiesPolicyLabel}
            onFocusEvent={handleButton}
          />
          <ProfileButton
            buttonType="small"
            buttonId={policyType.SUBSCRIBER}
            buttonText={V.legalSectionSubscriberAgreementLabel}
            onFocusEvent={handleButton}
            onFocusDown=""
          />
        </div>
      </div>
      <div className="section-right">{renderPolicy}</div>
    </div>
  )
}
