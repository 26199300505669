import { KeyCode, KeyName } from '@constants/index'
import { formatTime } from '@utils/helpers'
import React, { RefObject, useCallback, useEffect, useState } from 'react'
import './PlayerProgressBar.scss'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import classNames from 'classnames'

interface PlayerProgressBarProps {
  currentTime: number
  isProgressTooltipVisible: boolean
  progressRef: RefObject<HTMLProgressElement>
  pause: () => void
  hidePlayerControls: (shouldHide: boolean) => void
  setProgressTooltipVisible: (isProgressTooltipVisible: boolean) => void
  duration: number
  playFromPosition: (position: number) => void
  playPauseButtonRef: RefObject<HTMLButtonElement>
  isLive: boolean
}

export const PlayerProgressBar = ({
  currentTime,
  isProgressTooltipVisible,
  progressRef,
  pause,
  hidePlayerControls,
  setProgressTooltipVisible,
  duration,
  playFromPosition,
  playPauseButtonRef,
  isLive,
}: PlayerProgressBarProps) => {
  const [progressPosition, setProgressPosition] = useState(0)

  const handleProgressKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLProgressElement>) => {
      if (progressRef.current) {
        const progressMovementInSeconds = 5

        // Decrease progress position on left arrow key press for 5 seconds
        if (e.key === KeyName.ArrowLeft) {
          pause()
          hidePlayerControls(false)
          setProgressTooltipVisible(true)
          setProgressPosition(Math.max(progressPosition - progressMovementInSeconds, 0))
          e.preventDefault()
          e.stopPropagation()
        }
        // Increase progress position on right arrow key press for 5 seconds
        else if (e.key === KeyName.ArrowRight) {
          pause()
          hidePlayerControls(false)
          setProgressTooltipVisible(true)
          setProgressPosition(Math.min(progressPosition + progressMovementInSeconds, duration))
          e.preventDefault()
          e.stopPropagation()
        }
        // Play from the current progress position on enter key press
        else if (e.key === KeyName.Enter || e.keyCode === KeyCode.Enter) {
          playFromPosition(progressPosition)
          setProgressTooltipVisible(false)
          playPauseButtonRef?.current?.focus()
          e.preventDefault()
          e.stopPropagation()
        }
        // Play from the current time position on back key press
        else if (e.key === KeyName.Escape || e.keyCode === KeyCode.TizenBackKey || e.keyCode === KeyCode.WebosBackKey) {
          playPauseButtonRef?.current?.focus()
          setProgressTooltipVisible(false)
        }
      }
    },
    [
      progressRef,
      pause,
      hidePlayerControls,
      setProgressTooltipVisible,
      progressPosition,
      duration,
      playFromPosition,
      playPauseButtonRef,
    ],
  )

  const handleProgressClick = (e: React.MouseEvent<HTMLProgressElement>) => {
    if (progressRef.current) {
      const progressBar = progressRef.current
      const progressBarWidth = progressBar.clientWidth
      const clickPosition = e.nativeEvent.offsetX

      const newPosition = (clickPosition / progressBarWidth) * duration

      setProgressPosition(newPosition)
      playFromPosition(newPosition)
      hidePlayerControls(true)
      playPauseButtonRef?.current?.focus()
    }
  }

  const handleProgressBlur = () => {
    setProgressTooltipVisible(false)
    setProgressPosition(currentTime)
  }

  useEffect(() => {
    setProgressPosition(currentTime)
  }, [currentTime])

  return (
    <div className="player-controls-progress-bar">
      {!isLive && (
        <Text tag={TextTag.SPAN} className="playback-time">
          {formatTime(currentTime)}
        </Text>
      )}
      <div className="player-controls-progress-bar-container">
        {isProgressTooltipVisible && (
          <div
            className="tooltip"
            style={{
              left: `calc(${(progressPosition / duration) * 100}% - 40px)`,
            }}
          >
            <Text tag={TextTag.SPAN}>{formatTime(progressPosition)}</Text>
          </div>
        )}
        <div className="progress-bar">
          {isLive ? (
            <div
              className="progress-indicator"
              style={{
                left: '100%',
                transform: `translateX(-50%)`,
              }}
            >
              <div className={isProgressTooltipVisible ? 'indicator-line' : 'indicator-dot'}></div>
            </div>
          ) : (
            <div
              className="progress-indicator"
              style={{
                left: isProgressTooltipVisible
                  ? `calc(${(progressPosition / duration) * 100}% - 2px)`
                  : `${Math.max((progressPosition / duration) * 100, 0)}%`,
                transform: `translateX(-50%)`,
              }}
            >
              <div className={isProgressTooltipVisible ? 'indicator-line' : 'indicator-dot'}></div>
            </div>
          )}
          <progress
            className={classNames({
              focusable: !isLive,
            })}
            value={currentTime}
            max={duration}
            tabIndex={-1}
            ref={progressRef}
            onKeyDown={(e) => handleProgressKeyDown(e)}
            onClick={handleProgressClick}
            data-sn-left={progressPosition === 0 && ''}
            onFocus={() => setProgressTooltipVisible(true)}
            onBlur={() => {
              handleProgressBlur()
            }}
            style={{
              background: `linear-gradient(to right, rgba(var(--textColor-rgb), 0.8) ${
                (progressPosition / duration) * 100
              }%, transparent ${(progressPosition / duration) * 100}%)`,
            }}
          />
        </div>
      </div>
      {isLive ? (
        <Text tag={TextTag.P} className="live-indicator">
          <Text tag={TextTag.SPAN}>•</Text>
          <Text tag={TextTag.SPAN} className="live-indicator-text">
            LIVE
          </Text>
        </Text>
      ) : (
        <Text tag={TextTag.SPAN} className="playback-time">
          {formatTime(duration)}
        </Text>
      )}
    </div>
  )
}
