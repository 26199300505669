import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './Search.scss'
import { Movie } from '@interfaces/index'
import { navigateSearchFromKeyboard, disableMoveDown, disableMoveUp } from '@utils/search.utils'
import { QueryParams } from '@services/dynamic-page'
import { platformType, subscriptionPlanType } from '@constants/subscription.const'
import { routes } from '@constants/routes.const'
import { fetchToApi } from '@services/api'
import Card from '@components/Cards/Card'
import { fetchMethods, itemsPerPage } from '@constants/api.const'
import SearchInputField from '@components/Search/SearchInputField/SearchInputField'
import { RowTypes } from 'components'
import DotSpinner from '@components/DotSpinner/DotSpinner'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import { useDebounce } from '@hooks/useDebounce'
import { useTypedSelector } from 'store'
import SearchNoResultLabels from '@components/SearchNoResultLabels/SearchNoResultLabels'
import { scrollToTop } from '@utils/scroll.utils'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import { noResultsQueryParams } from '@constants/index'

interface SearchProps {
  showMenu: (status: boolean) => void
}

export const Search = ({ showMenu }: SearchProps) => {
  const V = useTypedSelector((state) => state.vocabulary.data)
  const [isLoading, setIsLoading] = useState(false)
  const [focusInput, setFocusInput] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState(searchParams.get('q') || '')
  const [filteredData, setFilteredData] = useState<Movie[] | undefined>([])
  const [noMatchData, setNoMatchData] = useState<Movie[] | undefined>([])
  const [initialPage, setInitialPage] = useState(0)
  const [initialFetchDone, setInitialFetchDone] = useState(false)
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const debounceDelay = 2000
  const debouncedSearchValue = useDebounce(searchValue, debounceDelay)
  const userDataObjectLS = JSON.parse(localStorage.getItem('user') as string)
  const subscriptionPlanLS = userDataObjectLS?.subscription
  const substringRoute = routes.SEARCH.substring(1)
  const [allPagesFromAPI, setAllPagesFromAPI] = useState(0)
  const [lastFocusedCard, setLastFocusedCard] = useState('')
  const [lastFocusedSuggestion, setLastFocusedSuggestion] = useState('')
  const [latestSearchQuery, setLatestSearchQuery] = useState('')
  const focusOnLastSelectedCard = isLoading ? '' : lastFocusedCard ? `#${lastFocusedCard}` : undefined
  const suggestionsList = filteredData?.slice(0, 6)
  const noMatchResult = debouncedSearchValue && filteredData?.length === 0 && !isLoading
  const smoothScrollValue = 200
  const { query } = useParams()

  const searchResults = debouncedSearchValue && filteredData && filteredData?.length > 0 && (
    <Text tag={TextTag.P} className="search-title">
      {V.searchResultsTitle} {debouncedSearchValue}
    </Text>
  )

  const searchQueryParams: QueryParams = useMemo(() => {
    return {
      platform: platformType.CTV,
      product: subscriptionPlanLS || subscriptionPlanType.FREE,
      q: debouncedSearchValue,
      page: initialPage.toString(),
      perpage: itemsPerPage,
    }
  }, [debouncedSearchValue, initialPage, subscriptionPlanLS])

  const showSuggestionsList = searchValue.length > 0 && searchQueryParams.q

  const handleValueInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event?.target?.value)
  }

  const handleFocusInput = useCallback(() => {
    setFocusInput(true)
  }, [])

  const handleBlurInput = useCallback(() => {
    setFocusInput(false)
  }, [])

  const handleNavigateFromKeyboard = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      navigateSearchFromKeyboard(event, searchValue, searchBoxRef, inputRef)
    },
    [searchValue],
  )

  const initialDataFetch = useCallback(() => {
    if (debouncedSearchValue.length > 0) {
      setIsLoading(true)
      setInitialPage(0)
      scrollToTop()
      setLastFocusedCard('')
      setLastFocusedSuggestion('')

      const updatedQueryParams: QueryParams = {
        ...searchQueryParams,
        q: debouncedSearchValue,
        page: '0',
      }

      fetchToApi(substringRoute, fetchMethods.GET, updatedQueryParams, undefined, undefined, false, true)
        .then((response) => {
          setFilteredData(response?.hits)
          setAllPagesFromAPI(response.nbPages)
          setIsLoading(false)
          setInitialFetchDone(true)
        })
        .catch(() => {
          console.log('Error while searching movies!')
          setIsLoading(false)
          setInitialFetchDone(true)
        })
    }
  }, [debouncedSearchValue, searchQueryParams, substringRoute])

  const loadMoreDataOnScroll = useCallback(() => {
    setIsLoading(true)
    setInitialPage((prevPage) => prevPage + 1)

    const updatedQueryParams: QueryParams = {
      ...searchQueryParams,
      page: (initialPage + 1).toString(),
    }
    fetchToApi(substringRoute, fetchMethods.GET, updatedQueryParams, undefined, undefined, false, true)
      .then((response) => {
        setFilteredData((prevData) => {
          if (!prevData) {
            setIsLoading(false)
            return [...response.hits]
          }

          const newItems = response.hits.filter(
            (newItem: Movie) => !prevData.some((prevItem) => prevItem.id === newItem.id),
          )
          return [...prevData, ...newItems]
        })

        setIsLoading(false)
      })
      .catch((error) => {
        console.log('Error while searching more movies:', error)
        setIsLoading(false)
      })
  }, [initialPage, searchQueryParams, substringRoute])

  const startInfiniteScroll = useCallback(() => {
    const distanceFromBottom = document.documentElement.scrollHeight - window.innerHeight - window.scrollY

    if (distanceFromBottom < smoothScrollValue && filteredData?.length && initialPage < allPagesFromAPI - 1) {
      setIsLoading(true)
      loadMoreDataOnScroll()
    }
  }, [allPagesFromAPI, filteredData?.length, initialPage, loadMoreDataOnScroll])

  const focusOnSuggestion = useCallback(
    (elements: Movie[]) => {
      if (lastFocusedSuggestion) {
        return `#${lastFocusedSuggestion}`
      } else {
        return `#${elements[0].id}`
      }
    },
    [lastFocusedSuggestion],
  )

  const updateLastFocusedSuggestion = (item: Movie) => {
    setLastFocusedSuggestion(item.id)
  }

  const focusDownOnSuggestion = (index: number, list: Movie[]) => {
    if (index === list.length - 1) {
      return ''
    } else {
      return undefined
    }
  }

  const updateLastFocusedCard = (item: Movie) => {
    setLastFocusedCard(`search-${item.id}`)
  }

  useEffect(() => {
    showMenu(true)
  }, [showMenu])

  useEffect(() => {
    fetchToApi(substringRoute, fetchMethods.GET, noResultsQueryParams, undefined, undefined, false, true)
      .then((response) => {
        setNoMatchData(response?.hits)
        setIsLoading(false)
      })
      .catch(() => {
        console.log('Error while searching movies!')
        setIsLoading(false)
      })
  }, [substringRoute])

  useEffect(() => {
    query && setSearchValue(query)
  }, [query])

  useEffect(() => {
    debouncedSearchValue ? setSearchParams({ q: debouncedSearchValue }) : setSearchParams('')
  }, [debouncedSearchValue, setSearchParams])

  useEffect(() => {
    if (
      (!initialFetchDone && debouncedSearchValue.length === 0) ||
      (debouncedSearchValue.length > 0 && debouncedSearchValue !== latestSearchQuery)
    ) {
      setFilteredData([])
      initialDataFetch()
      setLatestSearchQuery(debouncedSearchValue)
    }
  }, [
    debouncedSearchValue,
    subscriptionPlanLS,
    initialFetchDone,
    substringRoute,
    searchQueryParams,
    latestSearchQuery,
    initialDataFetch,
  ])

  useEffect(() => {
    window.addEventListener('scroll', startInfiniteScroll)
    window.addEventListener('keydown', startInfiniteScroll)

    return () => {
      window.removeEventListener('scroll', startInfiniteScroll)
      window.removeEventListener('keydown', startInfiniteScroll)
    }
  }, [startInfiniteScroll])

  useEffect(() => {
    if (focusInput) {
      inputRef.current?.focus()
    }
    if (inputRef.current && !focusInput) {
      inputRef.current.selectionStart = inputRef.current.selectionEnd = searchValue.length
    }
  }, [focusInput, searchValue.length])

  const renderSearchCard = useMemo(
    () => (elements: Movie[] | undefined, results: boolean) => {
      const noResultsArray = elements?.slice(0, 9)

      return (
        <div className="search-content" ref={scrollRef}>
          {!results && (
            <>
              <Text tag={TextTag.P} className="search-no-result">
                {V.searchPageExploreTitles}
              </Text>
              <div className="card-wrapper">
                {elements &&
                  noResultsArray?.map((element: Movie, index: number) => {
                    return (
                      <Card
                        key={`${element.id}_${index}`}
                        item={element}
                        rowType={RowTypes.SEARCH}
                        index={index}
                        isFocusable
                        disableMoveDown={disableMoveDown(index, elements)}
                        disableMoveUp={disableMoveUp(index)}
                        lastFocusedCard={() => updateLastFocusedCard(element)}
                        onFocusLeft={noMatchData && undefined}
                      />
                    )
                  })}
              </div>
            </>
          )}
          {results && (
            <div className="card-wrapper">
              {elements &&
                elements.map((element: Movie, index: number) => {
                  return (
                    <Card
                      key={`${element.id}_${index}`}
                      item={element}
                      rowType={RowTypes.SEARCH}
                      index={index}
                      isFocusable
                      disableMoveDown={disableMoveDown(index, elements)}
                      disableMoveUp={disableMoveUp(index)}
                      lastFocusedCard={() => updateLastFocusedCard(element)}
                      onFocusLeft={index % 3 === 0 && filteredData ? focusOnSuggestion(elements) : undefined}
                    />
                  )
                })}
            </div>
          )}
        </div>
      )
    },
    [V.searchPageExploreTitles, filteredData, focusOnSuggestion, noMatchData],
  )

  const handleNavigation = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent, url: string) => {
      e.preventDefault()

      onEnterKeyPressAndClick(e, () => navigate(url))
    },
    [navigate],
  )

  return (
    <div className="search-page">
      <div className="left-part-suggestions">
        <div className="inner-container">
          <SearchInputField
            wrapperRef={searchBoxRef}
            inputRef={inputRef}
            value={searchValue}
            onValueInput={handleValueInput}
            onFocusInput={handleFocusInput}
            onBlurInput={handleBlurInput}
            lastFocusedCard={lastFocusedCard ?? undefined}
            onKeyDownInput={handleNavigateFromKeyboard}
          />
          {showSuggestionsList && (
            <div className="results" id="search-results">
              {suggestionsList?.map((result, index) => (
                <div
                  key={`${result.id}-${index}`}
                  className="suggestion focusable"
                  id={result.id}
                  tabIndex={-1}
                  onKeyDown={(e) => handleNavigation(e, result.callToActions.webUrl)}
                  onClick={(e) => handleNavigation(e, result.callToActions.webUrl)}
                  data-sn-down={focusDownOnSuggestion(index, suggestionsList)}
                  data-sn-right={focusOnLastSelectedCard}
                  onFocus={() => updateLastFocusedSuggestion(result)}
                >
                  <Text tag={TextTag.P}>{result.title}</Text>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="right-part-results">
        <div className="search-text">
          {!debouncedSearchValue && (
            <Text tag={TextTag.P} className="search-title">
              {V.searchPageLabel}
            </Text>
          )}
          {searchResults}
          {noMatchResult && <SearchNoResultLabels value={debouncedSearchValue} />}
        </div>
        {debouncedSearchValue && renderSearchCard(filteredData, true)}
        {noMatchResult && renderSearchCard(noMatchData, false)}
        {isLoading && searchValue.length > 0 && <DotSpinner isSearch />}
      </div>
    </div>
  )
}
