import './Header.scss'
import { VelvetLogo } from '@assets/index'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import { ReactNode } from 'react'

interface HeaderProps {
  label?: string
  element?: ReactNode
}

export const Header = ({ label, element }: HeaderProps) => {
  return (
    <div className="header">
      {label && <Text tag={TextTag.SPAN}>{label}</Text>}
      {element}
      <VelvetLogo />
    </div>
  )
}
