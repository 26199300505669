import { Menu } from '@interfaces/index'
import { FETCH_MENU_FAILURE, FETCH_MENU_SUCCESS, SET_OPEN_MENU } from '../actions/menuActions'
import { FetchMenuFailure, FetchMenuRequest, FetchMenuSuccess, setMenu } from '@actions-types/menuActionTypes'

export type MenuAction = FetchMenuRequest | FetchMenuSuccess | FetchMenuFailure | setMenu
interface MenuState {
  openMenu: boolean
  data: Menu
  initialRoute: string | null
  error?: string
}

const initialState = {
  openMenu: false,
  initialRoute: null,
  data: {
    mainMenu: {
      content: [
        {
          availablePlatforms: [],
          callToActions: {
            navType: '',
            dataUrl: null,
            structureUrl: '',
            webUrl: '',
            templateId: '',
          },
          icon: '',
          id: 0,
          order: 0,
          text: '',
        },
      ],
    },
  },
  error: '',
}

const menuReducer = (state: MenuState = initialState, action: MenuAction): MenuState => {
  switch (action.type) {
    case SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.payload.openMenu,
      }
    case FETCH_MENU_SUCCESS:
      return {
        ...state,
        data: (action as FetchMenuSuccess).payload,
        initialRoute: (action as FetchMenuSuccess).payload?.mainMenu?.content[1].callToActions?.webUrl,
        error: '',
      }
    case FETCH_MENU_FAILURE:
      return { ...state, error: (action as FetchMenuFailure).payload }
    default:
      return state
  }
}

export default menuReducer
