import React, { useCallback, useRef, useState } from 'react'
import { useEffect } from 'react'
import './PlayerModal.scss'
import { onBackKeyPress, onEnterKeyPress } from '@utils/helpers'
import SpatialNavigation from '@utils/spatial-navigation.js'
import Title from '@components/Title/Title'
import { setAudio, setSubtitle } from '@actions/playerActions'
import { useDispatch } from 'react-redux'
import { Audio, Subtitle } from '@interfaces/player.interface'
import { useTypedSelector } from 'store'
import ModalButton from './ModalButton/ModalButton'
import { playerButtons } from '@constants/player.const'
import PrimaryPlayerButton from '@components/PlayerControls/PrimaryPlayerButton/PrimaryPlayerButton'
import { BackArrow } from '@assets/index'

interface PlayerModalProps {
  showModal?: boolean
  setShowModal: (status: boolean | ((prev: boolean) => boolean)) => void
  play: () => void
  pause: () => void
  continuePlaying: boolean
}

const PlayerModal = ({ setShowModal, play, pause, continuePlaying }: PlayerModalProps) => {
  const SN = SpatialNavigation
  const [buttonActive, setButtonActive] = useState('')
  const subtitleRef = useRef<HTMLDivElement>(null)
  const audioRef = useRef<HTMLDivElement>(null)
  const offSubtitlesRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const savedSubtitle = useTypedSelector((state) => state.player.subtitle)
  const savedAudio = useTypedSelector((state) => state.player.audio)
  const subtitlesList = useTypedSelector((state) => state.player.subtitleList)
  const audioList = useTypedSelector((state) => state.player.audioList)
  const V = useTypedSelector((state) => state.vocabulary.data)
  const isPlaying = useTypedSelector((state) => state.player.isPlaying)
  const singleAudioItem = 1

  const closePlayerModal = useCallback(() => {
    setShowModal((prev) => !prev)
    !continuePlaying && play()
    SN.focus(`#${playerButtons.AUDIO_AND_SUBTITLES_BUTTON}`)
  }, [SN, continuePlaying, play, setShowModal])

  const closePlayerModalOnBackKey = useCallback(
    (e: KeyboardEvent) => {
      onBackKeyPress(e, closePlayerModal)
    },
    [closePlayerModal],
  )

  useEffect(() => {
    if (!continuePlaying) {
      pause()
    } else {
      if (isPlaying) {
        play()
      } else {
        pause()
      }
    }
  }, [continuePlaying, isPlaying, pause, play])

  useEffect(() => {
    document.addEventListener('keydown', closePlayerModalOnBackKey, true)

    return () => document.removeEventListener('keydown', closePlayerModalOnBackKey, true)
  })

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.focus()
    } else if (offSubtitlesRef.current) {
      offSubtitlesRef.current.focus()
    } else if (subtitleRef.current) {
      subtitleRef.current.focus()
    }
  }, [])

  const handleButton = useCallback((e: React.FocusEvent) => {
    setButtonActive(e.target.id)
  }, [])

  const handleUpdateStore = useCallback((e: React.KeyboardEvent, onKeyDown: () => void) => {
    e.preventDefault()

    onEnterKeyPress(e, () => onKeyDown())
  }, [])

  const focusAudioButton = (audio: Audio) => {
    if (savedAudio && audio.id === savedAudio) {
      return audioRef
    }
  }

  const buttonClassname = (itemType: string, itemId: string) => `${itemType} ${buttonActive === itemId ? 'active' : ''}`

  const labelClassname = (condition: boolean) => (condition ? 'chosen' : '')

  return (
    <div className="modal player-modal-focus">
      <div className="inner">
        <PrimaryPlayerButton onClick={closePlayerModal} className="back-button">
          <BackArrow />
        </PrimaryPlayerButton>
        <div className="audio-part">
          <Title title={V.playerAudio} />
          {audioList &&
            audioList.map((audio: Audio, index: number) => (
              <ModalButton
                key={audio.id}
                id={audio.id}
                buttonClassName={buttonClassname('audio-item', audio.id)}
                onFocus={(e) => handleButton(e)}
                onKeyDown={(e) => handleUpdateStore(e, () => dispatch(setAudio(e.currentTarget.id)))}
                onClick={(e) => dispatch(setAudio(e.currentTarget.id))}
                buttonRef={focusAudioButton(audio)}
                labelClassName={labelClassname(savedAudio === audio.id || audioList.length === singleAudioItem)}
                label={audio.lang}
                onFocusDown={index === audioList.length - 1 ? '' : undefined}
              />
            ))}
        </div>
        {subtitlesList.length > 0 && (
          <div className="subtitles-part">
            <Title title={V.playerSubtitles} />
            {subtitlesList.map((subtitle: Subtitle) => (
              <ModalButton
                key={subtitle.id}
                id={subtitle.id}
                buttonClassName={buttonClassname('subtitle-item', subtitle.id)}
                onFocus={(e) => handleButton(e)}
                onKeyDown={(e) => handleUpdateStore(e, () => dispatch(setSubtitle(e.currentTarget.id)))}
                onClick={(e) => dispatch(setSubtitle(e.currentTarget.id))}
                buttonRef={subtitle.id === savedSubtitle ? subtitleRef : null}
                labelClassName={labelClassname(savedSubtitle === subtitle.id)}
                label={subtitle.lang}
              />
            ))}
            <ModalButton
              id={'subtitle-off'}
              buttonClassName={buttonClassname('subtitle-item', 'subtitle-off')}
              onFocus={(e) => handleButton(e)}
              onKeyDown={(e) => handleUpdateStore(e, () => dispatch(setSubtitle('')))}
              onClick={() => dispatch(setSubtitle(''))}
              buttonRef={offSubtitlesRef}
              labelClassName={labelClassname(savedSubtitle === 'subtitle-off')}
              label={V.playerOffButton}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PlayerModal
