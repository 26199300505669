import { Splide, SplideSlide } from '@splidejs/react-splide'
import React, { useCallback, useRef, useState } from 'react'
import Card from '@components/Cards/Card'
import { RailsProps, Movie } from '@interfaces/index'
import './Rail.scss'
import { KeyName, splidesPerMove } from '@constants/index'
import { useLocation, useNavigate } from 'react-router-dom'
import Title from '@components/Title/Title'
import useCarousel from '@hooks/useCarousel'
import { CardSessionStorage } from '@interfaces/index'
import { addRailItemToSS } from '@utils/helpers'
import { focusableRoutes } from '@constants/routes.const'

const Rail = ({ section }: RailsProps) => {
  const nextSlide = useRef<HTMLButtonElement>(null)
  const prevSlide = useRef<HTMLButtonElement>(null)
  const carouselRef = useRef<HTMLDivElement>(null)
  const carouselSplideRef = useRef<Splide>(null)
  const [isFocus, setIsFocus] = useState(false)
  const lastFocusedCardInSS: CardSessionStorage = JSON.parse(sessionStorage.getItem('lastFocusedCard-home') as string)
  const [currentIndex, setCurrentIndex] = useState<number>(
    lastFocusedCardInSS && lastFocusedCardInSS.railId === `rail-${section.id}` ? lastFocusedCardInSS.cardIndex : 0,
  )
  const onFocusLeft = currentIndex === 0 && `#${focusableRoutes.MENU}`
  const onFocusRight = ''
  const navigate = useNavigate()
  const railSpeed = 1000
  const location = useLocation()
  const path = location.pathname

  const moveLeft = useCallback(() => {
    prevSlide.current?.click()
  }, [])

  const moveRight = useCallback(() => {
    nextSlide.current?.click()
  }, [])

  useCarousel(carouselRef.current, moveLeft, moveRight, currentIndex)

  const onSplideMove = (index: number) => {
    setCurrentIndex(index)
    if (index !== 0 && prevSlide.current) {
      prevSlide.current.disabled = false
    }
  }

  const navigateToDetailsPage = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code === KeyName.Enter) {
        addRailItemToSS(`rail-${section.id}`, `${section.items[currentIndex].id}`, currentIndex, path)
        navigate(section.items[currentIndex].callToActions.webUrl)
      }
    },
    [currentIndex, navigate, path, section.id, section.items],
  )

  const handleOnFocus = () => setIsFocus(true)

  const handleOnBlur = () => setIsFocus(false)

  const handleOnMouseDown = (index: number) => setCurrentIndex(index)

  const activeCard = useCallback((index: number) => isFocus && currentIndex === index, [currentIndex, isFocus])

  return (
    <>
      {section.items.length > 0 && (
        <>
          {section.title && <Title className="rail-section-title" title={section.title} />}
          <div
            className="focusable rail scroll-center"
            tabIndex={-1}
            ref={carouselRef}
            data-sn-left={onFocusLeft}
            data-sn-right={onFocusRight}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onKeyDown={navigateToDetailsPage}
            id={`rail-${section.id}`}
          >
            <div className="box-shadow-splide"></div>
            <Splide
              onMove={(_splide, index) => onSplideMove(index)}
              options={{
                perMove: splidesPerMove,
                speed: railSpeed,
                gap: '0',
                slideFocus: false,
                pauseOnFocus: true,
                pagination: false,
                drag: false,
                focus: 0,
                start: currentIndex,
              }}
              ref={carouselSplideRef}
            >
              {section.items.map((item: Movie, idx) => {
                return (
                  <SplideSlide key={`${item.id}_${idx}`} onMouseDown={() => handleOnMouseDown(idx)}>
                    <Card item={item} rowType={section.cardTemplate} isActive={activeCard(idx)} isFocusable={false} />
                  </SplideSlide>
                )
              })}
              <div className="splide__arrows">
                <button className="splide__arrow splide__arrow--prev" ref={prevSlide}></button>
                <button className="splide__arrow splide__arrow--next" ref={nextSlide}></button>
              </div>
            </Splide>
          </div>
        </>
      )}
    </>
  )
}

export default Rail
