import { useLocation } from 'react-router-dom'
import { mainRoutes, routes } from '@constants/routes.const'
import HomepageSkeleton from '@pages/Skeleton/HomepageSkeleton/HomepageSkeleton'
import GridSkeleton from '@pages/Skeleton/GridSkeleton/GridSkeleton'
import MoviesDetailsSkeleton from '@pages/Skeleton/DetailsPageSkeleton/MoviesDetailsSkeleton/MoviesDetailsSkeleton'
import SeriesDetailsSkeleton from '@pages/Skeleton/DetailsPageSkeleton/SeriesDetailsSkeleton/SeriesDetailsSkeleton'
import './Skeleton.scss'

const Skeleton = () => {
  const location = useLocation()

  const getPageSkeleton = () => {
    switch (true) {
      case location.pathname.includes(mainRoutes.HOME):
        return <HomepageSkeleton />
      case location.pathname.startsWith(routes.CATEGORY + '/'):
        return <GridSkeleton />
      case location.pathname.startsWith(routes.MOVIES + '/'):
        return <MoviesDetailsSkeleton />
      case location.pathname.startsWith(routes.SERIES + '/'):
        return <SeriesDetailsSkeleton />
      case location.pathname === routes.MY_LIST:
        return <GridSkeleton />
      default:
        return null
    }
  }

  return <div className="skeleton-wrapper">{getPageSkeleton()}</div>
}

export default Skeleton
