import axios from 'axios'
import { Dispatch } from 'redux'
import { setPageNotFound } from './pageNotFoundActions'
import { setSkeleton } from './skeletonActions'

export const FETCH_PAGE_REQUEST = 'FETCH_PAGE_REQUEST' as const
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS' as const
export const FETCH_PAGE_FAILURE = 'FETCH_PAGE_FAILURE' as const
export const RESET_PAGE = 'RESET_PAGE' as const

export const fetchPageRequest = () => ({ type: FETCH_PAGE_REQUEST })
export const fetchPageSuccess = (page: any) => ({
  type: FETCH_PAGE_SUCCESS,
  payload: page,
})

export const fetchPageFailure = (error: string) => ({
  type: FETCH_PAGE_FAILURE,
  payload: error,
})

export const resetPage = () => ({ type: RESET_PAGE })

export const fetchPage = (endpoint: string | null | undefined) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setPageNotFound(false))
    dispatch(fetchPageRequest())
    axios
      .get(endpoint as string)
      .then((res) => {
        const page = res.data
        dispatch(fetchPageSuccess(page))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(fetchPageFailure(errorMsg))
        dispatch(setPageNotFound(true))
        dispatch(setSkeleton(false))
      })
  }
}
