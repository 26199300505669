import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MobileDeviceIcon } from '@assets/index'
import { onBackKeyPress, onEnterKeyPress } from '@utils/helpers'
import './PinAndPair.scss'
import { Header } from '@components/Header/Header'
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton'
import { PinAndPairStep } from '@components/PinAndPairStep/PinAndPairStep'
import QRCode from 'react-qr-code'
import { fetchLoginCode, fetchCustomToken } from '@services/pinAndPair'
import { loginWithCustomToken } from '@utils/auth'
import { routes } from '@constants/routes.const'
import DotSpinner from '@components/DotSpinner/DotSpinner'
import { formatLoginCode } from '@utils/pinAndPair.util'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface PinAndPairProps {
  showMenu: (status: boolean) => void
}

interface LoginData {
  loginCode: number
  websiteLink: string
}

export const PinAndPair = ({ showMenu }: PinAndPairProps) => {
  const navigate = useNavigate()

  const [loginData, setLoginData] = useState<LoginData | null>()
  const [isCustomTokenAvailable, setIsCustomTokenAvailable] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const buttonLogInWithRemote = useRef<HTMLButtonElement>(null)
  const continueButtonRef = useRef<HTMLButtonElement>(null)
  const customTokenIntervalDuration = 15000

  const closePinAndPairPage = useCallback(
    (e: KeyboardEvent) =>
      onBackKeyPress(e, () => {
        navigate(-1)
      }),
    [navigate],
  )

  const navigateBack = useCallback(() => navigate(-1), [navigate])

  const navigateToHomePage = useCallback(() => {
    navigate(routes.HOME)
  }, [navigate])

  const getLoginData = async () => {
    try {
      const response = await fetchLoginCode()
      if (response) {
        setLoginData(response)
        setIsLoading(false)
      }
    } catch (error) {
      return error
    }
  }

  const handleNewCodeGeneration = useCallback((e: React.KeyboardEvent) => {
    onEnterKeyPress(e, () => {
      setError(false)
      getLoginData()
      buttonLogInWithRemote?.current?.focus()
    })
  }, [])

  useEffect(() => {
    showMenu(false)
    if (!isLoading && buttonLogInWithRemote.current) {
      buttonLogInWithRemote.current.focus()
    }
    return () => {
      showMenu(true)
    }
  }, [showMenu, isLoading])

  useEffect(() => {
    getLoginData()
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', closePinAndPairPage, false)

    return () => document.removeEventListener('keydown', closePinAndPairPage, false)
  })

  useEffect(() => {
    const getCustomToken = async () => {
      if (loginData?.loginCode) {
        try {
          const response = await fetchCustomToken(loginData.loginCode)
          if (response.data.customToken) {
            clearInterval(customTokenRetrievalInterval)
            setIsCustomTokenAvailable(true)
            await loginWithCustomToken(response.data.customToken)
            navigateToHomePage()
          }
        } catch (error) {
          setError(true)
          clearInterval(customTokenRetrievalInterval)
        }
      }
    }
    getCustomToken()

    const customTokenRetrievalInterval = setInterval(getCustomToken, customTokenIntervalDuration)

    return () => clearInterval(customTokenRetrievalInterval)
  }, [loginData?.loginCode, navigateToHomePage])

  useEffect(() => {
    if (error) {
      continueButtonRef?.current?.focus()
    }
  }, [error])

  if (isLoading) return <DotSpinner />

  return (
    <div className="pin-and-pair-container">
      <Header label="" />
      {isCustomTokenAvailable && (
        <div className="pin-and-pair-overlay">
          <DotSpinner />
        </div>
      )}
      <div className="pin-and-pair-content">
        <Text tag={TextTag.P} className="pin-and-pair-title">
          Follow these steps on your mobile device or scan the QR code
        </Text>
        <div className="steps">
          <div className="pin-and-pair-steps-container">
            <PinAndPairStep
              stepNumber="Step 1"
              label="Go to the website:"
              value={loginData?.websiteLink}
              valueClassName="url"
            />
            <PinAndPairStep
              stepNumber="Step 2"
              label="Enter the login code:"
              value={formatLoginCode(loginData?.loginCode)}
              valueClassName="code"
            />
            <PinAndPairStep stepNumber="Step 3" label="LogIn to Velvet. Now you can enjoy Velvet on your tv!" />
          </div>
          <div className="qr-code">
            <Text tag={TextTag.P} className="qr-container">
              {loginData?.websiteLink && <QRCode value={loginData.websiteLink} />}
            </Text>
          </div>
        </div>
        <div className="login-with-device-info">
          <MobileDeviceIcon className="login-with-device-icon" />
          <Text tag={TextTag.P} className="login-with-device-label">
            Continue on your device
          </Text>
        </div>
        {error && (
          <div className="pin-and-pair-overlay">
            <div className="code-expired">
              <Text tag={TextTag.P} className="code-expired-message">
                The code has expired.
              </Text>
              <Text tag={TextTag.P} className="code-expired-instructions">
                Please press "Continue" to generate a new one.
              </Text>
              <SecondaryButton onKeyDown={handleNewCodeGeneration} buttonRef={continueButtonRef} onFocusDown>
                Continue
              </SecondaryButton>
            </div>
          </div>
        )}
        <div className="button-container">
          <Text tag={TextTag.P}>or write your e-mail and password on your tv with remote control</Text>
          <SecondaryButton onClick={navigateBack} buttonRef={buttonLogInWithRemote}>
            LogIn with remote control
          </SecondaryButton>
        </div>
      </div>
    </div>
  )
}
