import React, { LegacyRef } from 'react'
import './SecondaryButton.scss'

interface SecondaryButtonPorps {
  onKeyDown?: (e: React.KeyboardEvent) => void
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children: React.ReactNode
  buttonRef?: LegacyRef<HTMLButtonElement>
  onFocusDown?: boolean
}

export const SecondaryButton = ({ onClick, children, buttonRef, onKeyDown, onFocusDown }: SecondaryButtonPorps) => {
  return (
    <button className="secondary-button focusable" onClick={onClick} onKeyDown={onKeyDown} ref={buttonRef} data-sn-down={onFocusDown && ""}>
      {children}
    </button>
  )
}
