import { Audio, Subtitle } from '@interfaces/player.interface'

export const SET_SUBTITLE = 'SET_SUBTITLE'
export const SET_SUBTITLE_LIST = 'SET_SUBTITLE_LIST'
export const SET_AUDIO = 'SET_AUDIO'
export const SET_AUDIO_LIST = 'SET_AUDIO_LIST'
export const SET_IS_PLAYING = 'SET_IS_PLAYING'

export const setSubtitle = (subtitle: string) => ({
  type: SET_SUBTITLE,
  payload: {
    subtitle,
  },
})

export const setSubtitleList = (subtitleList: Subtitle[]) => ({
  type: SET_SUBTITLE_LIST,
  payload: {
    subtitleList,
  },
})

export const setAudio = (audio: string) => ({
  type: SET_AUDIO,
  payload: {
    audio,
  },
})

export const setAudioList = (audioList: Audio[]) => ({
  type: SET_AUDIO_LIST,
  payload: {
    audioList,
  },
})

export const setIsPlaying = (isPlaying: boolean) => ({
  type: SET_IS_PLAYING,
  payload: {
    isPlaying,
  },
})
