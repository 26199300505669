import React, { LegacyRef } from 'react'

interface InputProps {
  className?: string
  value?: string
  placeholder?: string
  focusOnRight?: string
  focusOnLeft?: string
  focusOnUp?: string
  type: string
  name?: string
  id?: string
  inputRef?: LegacyRef<HTMLInputElement> | React.RefObject<HTMLInputElement>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onValueInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const Input = ({
  type,
  className,
  value,
  inputRef,
  onValueInput,
  onKeyDown,
  focusOnRight,
  focusOnUp,
  id,
  name,
  onChange,
  placeholder,
}: InputProps) => {
  return (
    <input
      className={className}
      id={id}
      name={name}
      type={type}
      value={value}
      ref={inputRef}
      onInput={onValueInput}
      onKeyDown={onKeyDown}
      data-sn-right={focusOnRight}
      data-sn-up={focusOnUp}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete="off"
    />
  )
}

export default Input
