import { HomeIcon, MoviesIcon, Plus, SearchIcon, SeriesIcon } from '@assets/index'

export const setMenuIcon = (icon: string) => {
  switch (icon) {
    case 'home':
      return <HomeIcon />
    case 'movies':
      return <MoviesIcon />
    case 'series':
      return <SeriesIcon />
    case 'search':
      return <SearchIcon />
    case 'default':
      return <Plus />
    default:
      return <Plus />
  }
}
