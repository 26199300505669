import React, { useCallback, useEffect, useState } from 'react'
import './Category.scss'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { RowTypes } from 'components'
import { addLastFocusedItemToSS, onBackKeyPress } from '@utils/helpers'
import { CardProps } from '@interfaces/index'
import SpatialNavigation from '@utils/spatial-navigation.js'
import Card from '@components/Cards/Card'
import EmptyListContainer from '@components/EmptyListContainer/EmptyListContainer'
import { getCategoryResults } from '@services/category'
import Skeleton from '@pages/Skeleton/Skeleton'
import { useTypedSelector } from 'store'
import { focusableRoutes } from '@constants/routes.const'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface CategoryProps {
  showMenu: (status: boolean) => void
}

const Category = ({ showMenu }: CategoryProps) => {
  const SN = SpatialNavigation
  const { chosenCategory } = useParams<Params>()
  const [categoryCards, setCategoryCards] = useState<CardProps[] | undefined>()
  const userObject = JSON.parse(localStorage.getItem('user') as string)
  const [isloading, setIsLoading] = useState(true)
  const userId = userObject?.firebaseUid
  const navigate = useNavigate()
  const V = useTypedSelector((state) => state.vocabulary.data)
  const lastFocusedCard = JSON.parse(sessionStorage.getItem('lastFocusedCard-category') as string)
  const findLastFocusedCard = categoryCards?.find((category) => category.id === lastFocusedCard)

  const navigateToHomePage = useCallback(
    (e: KeyboardEvent) =>
      onBackKeyPress(e, () => {
        navigate(-1)
        sessionStorage.removeItem('lastFocusedCard-category')
      }),
    [navigate],
  )

  useEffect(() => {
    document.addEventListener('keydown', navigateToHomePage, true)

    return () => {
      document.removeEventListener('keydown', navigateToHomePage, true)
    }
  }, [navigateToHomePage])

  useEffect(() => {
    try {
      if (userId) {
        setIsLoading(true)

        getCategoryResults(chosenCategory)
          .then((res) => {
            setCategoryCards(res)
            setIsLoading(false)
          })
          .catch((error) => {
            console.log('Error getting items from category list:', error)
            setIsLoading(false)
          })
      }
    } catch (error) {
      console.log(`Error getting items from Category ${chosenCategory}:`, error)
    }
  }, [chosenCategory, userId])

  useEffect(() => {
    showMenu(false)
    if (categoryCards) {
      lastFocusedCard && findLastFocusedCard ? SN.focus(`#${lastFocusedCard}`) : SN.focus(focusableRoutes.CATEGORY)
    }

    return () => showMenu(true)
  }, [categoryCards, SN, showMenu, lastFocusedCard, findLastFocusedCard])

  return (
    <div className="category-page">
      {!isloading && (
        <Text tag={TextTag.P} className="category-title">
          {chosenCategory}
        </Text>
      )}
      {isloading ? (
        <Skeleton />
      ) : (
        <>
          {categoryCards && categoryCards.length > 0 ? (
            <div className="card-wrapper">
              {categoryCards.map((element: CardProps, index: number) => {
                return (
                  <Card
                    key={element.id}
                    item={element}
                    rowType={RowTypes.MYLIST}
                    index={index}
                    isFocusable
                    lastFocusedCard={() => addLastFocusedItemToSS(element.id, focusableRoutes.CATEGORY)}
                  />
                )
              })}
            </div>
          ) : (
            <EmptyListContainer
              firstSubtitle={V.categoriesPageEmptyTitle}
              secondSubtitle={V.categoriesPageEmptyDescription}
              buttonTitle={V.categoriesPageEmptyButtonText}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Category
