import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import React from 'react'
import { useTypedSelector } from 'store'

interface PrivacyPolicyProps {
  scrollRef: React.LegacyRef<HTMLDivElement>
  onMouseEnter: () => void
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined
  focusOnLeft: string
}

const PrivacyPolicy = ({ scrollRef, onKeyDown, focusOnLeft, onMouseEnter }: PrivacyPolicyProps) => {
  const V = useTypedSelector((state) => state.vocabulary.data)

  return (
    <div
      id="legal-text"
      className="legal-content-text-item focusable"
      data-sn-left={focusOnLeft}
      tabIndex={-1}
      ref={scrollRef}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
    >
      <div className="legal-content-text-template">
        <div className="legal-content-text-template-title">{V.legalPrivacyPolicyTitle}</div>
        <div className="legal-content-text-template-description">
          <Text tag={TextTag.P} className="policy-introduction">
            {V.privacyPolicyText_1}
          </Text>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.privacyPolicyText_2}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_3}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_4}
              </Text>
            </ul>
          </>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.privacyPolicyText_5}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_6}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_7}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_8}
              </Text>
            </ul>
          </>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.privacyPolicyText_9}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_10}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_11}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_12}
              </Text>
            </ul>
          </>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.privacyPolicyText_13}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.privacyPolicyText_14}
              </Text>
            </ul>
          </>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
