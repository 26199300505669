import { useNavigate } from 'react-router-dom'
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton'
import { useCallback, useEffect, useRef } from 'react'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface ForgotPasswordInputSublabelProps {
  email: string
}

const ForgotPasswordInputSublabel = ({ email }: ForgotPasswordInputSublabelProps) => {
  const navigate = useNavigate()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  useEffect(() => {
    buttonRef && buttonRef.current?.focus()
  }, [])

  return (
    <div className="forgot-password_instructions">
      <Text tag={TextTag.P}>
        We've sent you an email to <Text tag={TextTag.SPAN}> {email}.</Text> Please follow the instructions to reset
        your password.
      </Text>
      <SecondaryButton buttonRef={buttonRef} onClick={navigateBack}>
        Back
      </SecondaryButton>
    </div>
  )
}

export default ForgotPasswordInputSublabel
