import PageNotFoundContent from '@components/PageNotFoundContent/PageNotFoundContent'
import { useEffect } from 'react'

interface PageNotFoundProps {
  showMenu?: (status: boolean) => void
}

const PageNotFound = ({ showMenu }: PageNotFoundProps) => {
  useEffect(() => {
    showMenu && showMenu(true)
  }, [showMenu])

  return <PageNotFoundContent />
}

export default PageNotFound
