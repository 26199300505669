import React from 'react'
import './SeasonTabsSkeleton.scss'

const SeasonTabsSkeleton = () => {
  return (
    <div className="season-tabs-skeleton-container">
      <div className="skeleton-element season-tab-skeleton"></div>
      <div className="skeleton-element season-tab-skeleton"></div>
      <div className="skeleton-element season-tab-skeleton"></div>
    </div>
  )
}

export default SeasonTabsSkeleton
