import React, { Ref, forwardRef, useCallback } from 'react'
import './ShowPassword.scss'
import { Tick } from '@assets/index'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface ShowPasswordProps {
  showPassword: boolean
  setShowPassword: (status: boolean | ((prev: boolean) => boolean)) => void
}

export const ShowPassword = forwardRef(
  ({ showPassword, setShowPassword }: ShowPasswordProps, ref: Ref<HTMLFormElement>) => {
    const handleSetIcon = useCallback(
      (e: React.KeyboardEvent | React.MouseEvent) => {
        onEnterKeyPressAndClick(e, () => setShowPassword((prev) => !prev))
      },
      [setShowPassword],
    )

    return (
      <form
        className="focusable showPassword"
        onKeyDown={handleSetIcon}
        onClick={handleSetIcon}
        tabIndex={-1}
        id="forgot"
        ref={ref}
      >
        <div className={` showPassword__${showPassword ? 'checkbox__checked' : 'checkbox'}`} id="forgotPassword">
          <Tick />
        </div>
        <Text tag={TextTag.SPAN}>Show password</Text>
      </form>
    )
  },
)

ShowPassword.displayName = 'ShowPassword'
