import React, { lazy } from 'react'
import { PageTemplates } from '@constants/index'
import { Root } from '@interfaces/index'
import PageNotFound from '@pages/PageNotFound/PageNotFound'

const Pages = {
  [PageTemplates.FEED]: lazy(() => import('@components/Feed/Feed').then((module) => ({ default: module.Feed }))),
  [PageTemplates.DETAILS]: lazy(() => import('@components/Details/Details')),
}

const renderPage = ({ page, showMenu }: Root) => {
  // Render page according to template
  if (Pages[page?.meta?.template]) {
    return React.createElement(Pages[page?.meta?.template], {
      page,
    })
  }
  return <PageNotFound showMenu={showMenu} />
}

export default renderPage
