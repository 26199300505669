import './ForgotPassword.scss'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../firebase'
import { sendResetPasswordEmail } from '@utils/auth'
import { emailLoginPattern, onBackKeyPress } from '@utils/helpers'
import { Header } from '@components/Header/Header'
import DotSpinner from '@components/DotSpinner/DotSpinner'
import { LoginInputField } from '@components/Login/LoginInputField/LoginInputField'
import ForgotPasswordInputSublabel from '@templates/ForgotPasswordInputSublabel/ForgotPasswordInputSublabel'
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton'

type ForgotPasswordProps = {
  showMenu: (status: boolean) => void
}

export const ForgotPassword = ({ showMenu }: ForgotPasswordProps) => {
  const [resetPasswordEmail, setResetPasswordEmail] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const resetPasswordLabel = 'Reset your password, write the email address associated with your Velvet account.'
  const forgotPasswordSublabel = !isFormSubmitted ? (
    resetPasswordLabel
  ) : (
    <ForgotPasswordInputSublabel email={resetPasswordEmail} />
  )

  useEffect(() => {
    showMenu(false)

    return () => {
      showMenu(true)
    }
  }, [showMenu])

  const handleSendResetPasswordEmail = () => {
    setLoading(true)
    sendResetPasswordEmail(auth, resetPasswordEmail)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const checkEmail = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    if (emailLoginPattern.test(resetPasswordEmail)) {
      setEmailErrorMessage(null)
      handleSendResetPasswordEmail()
      setIsFormSubmitted(true)
    } else {
      setEmailErrorMessage('Put the right e-mail format!')
    }
  }

  const handleResetPasswordEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResetPasswordEmail(e.target.value)
  }

  const closeForgotPasswordPage = useCallback(
    (e: KeyboardEvent) =>
      onBackKeyPress(e, () => {
        navigate(-1)
      }),
    [navigate],
  )

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  useEffect(() => {
    document.addEventListener('keydown', closeForgotPasswordPage, false)

    return () => document.removeEventListener('keydown', closeForgotPasswordPage, false)
  })

  return (
    <div className="forgot-password">
      <Header label="LogIn" />
      {loading ? (
        <DotSpinner />
      ) : (
        <>
          <div className="forgot-password_container">
            <LoginInputField
              type="text"
              label="Forgot Password"
              sublabel={forgotPasswordSublabel}
              inputValue={resetPasswordEmail}
              errorMessage={emailErrorMessage}
              isSubmitted={isFormSubmitted}
              onChange={handleResetPasswordEmail}
              onSubmit={checkEmail}
              inputRef={inputRef}
              placeholder="E-mail"
            />
          </div>
          {!isFormSubmitted && (
            <div className="forgot-password_buttons">
              <SecondaryButton onClick={navigateBack}>Back</SecondaryButton>
              <SecondaryButton onClick={checkEmail}>Send</SecondaryButton>
            </div>
          )}
        </>
      )}
    </div>
  )
}
