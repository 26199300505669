import Text from '@components/Text/Text'
import { useTypedSelector } from '../../store'
import './PageNotFoundContent.scss'
import { TextTag } from '@enums/textTag'

const PageNotFoundContent = () => {
  const V = useTypedSelector((state) => state.vocabulary.data)
  const showPageNotFound = useTypedSelector((state) => state.pageNotFound.show)

  return (
    <div className="page-not-found">
      {showPageNotFound && (
        <Text tag={TextTag.H1} className="page-not-found-text">
          {V.pageNotFound}
        </Text>
      )}
    </div>
  )
}

export default PageNotFoundContent
