import React, { LegacyRef } from 'react'
import { ChevronRight } from '@assets/index'
import './ProfileButton.scss'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

export interface ProfileButtonProps {
  buttonType: 'small' | 'medium' | 'large'
  buttonId: string
  buttonText: string
  buttonValue?: string
  className?: string
  onKeyDownEvent?: (e: React.KeyboardEvent) => void
  onClick?: (e: React.MouseEvent) => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  onFocusEvent?: (e: React.FocusEvent) => void
  buttonRef?: LegacyRef<HTMLDivElement>
  onFocusDown?: string
}

export const ProfileButton = ({
  buttonType,
  buttonId,
  buttonText,
  onKeyDownEvent,
  onFocusEvent,
  buttonRef,
  buttonValue,
  onClick,
  onMouseLeave,
  onMouseEnter,
  onFocusDown,
}: ProfileButtonProps) => {
  return (
    <div
      id={buttonId}
      className={`profile-button focusable ${buttonType}`}
      tabIndex={-1}
      onKeyDown={onKeyDownEvent}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocusEvent}
      ref={buttonRef}
      data-sn-down={onFocusDown}
    >
      <Text tag={TextTag.P} className="innerText">
        <Text tag={TextTag.SPAN}>{buttonText}</Text>
        <Text tag={TextTag.SPAN} className="displayName">
          {buttonValue}
        </Text>
      </Text>
      <ChevronRight />
    </div>
  )
}
