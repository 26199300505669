import HeroSkeleton from '@components/Skeleton/HeroSkeleton/HeroSkeleton'
import RailSkeleton from '@components/Skeleton/RailSkeleton/RailSkeleton'
import {
  categoryCardsPerRow,
  horizontalCardsPerRow,
  posterCardsPerRow,
  railRowsHomePage,
  verticalCardsPerRow,
} from '@constants/skeleton.const'
import './HomepageSkeleton.scss'
import React from 'react'

const HomepageSkeleton = () => {
  return (
    <div className="homepage-skeleton-wrapper">
      <HeroSkeleton page="home" />
      <RailSkeleton itemsType="category" itemsPosition="horizontal" itemsCount={categoryCardsPerRow} />
      {Array.from({ length: railRowsHomePage }, (_, index) => (
        <React.Fragment key={index}>
          <div className="skeleton-element skeleton-section-title"></div>
          <RailSkeleton itemsType="movie" itemsPosition="horizontal" itemsCount={horizontalCardsPerRow} />
        </React.Fragment>
      ))}
      <div className="skeleton-element skeleton-section-title"></div>
      <RailSkeleton itemsType="movie" itemsPosition="vertical" itemsCount={verticalCardsPerRow} />
      <div className="skeleton-element skeleton-section-title"></div>
      <RailSkeleton itemsType="poster" itemsPosition="horizontal" itemsCount={posterCardsPerRow} />
    </div>
  )
}

export default HomepageSkeleton
