import axios from 'axios'
import { CardProps } from '@interfaces/index'

export const getCategoryResults = async (genre: string | undefined) => {
  const moviesFromCategory = await axios
    .get(`${process.env.REACT_APP_BASE_URL}/parsed/en/movie/genre/${genre}`)
    .then((res) => res.data && res.data.items)

  const seriesFromCategory = await axios
    .get(`${process.env.REACT_APP_BASE_URL}/parsed/en/serie/genre/${genre}`)
    .then((res) => res.data && res.data.items)

  if (moviesFromCategory && seriesFromCategory) {
    let results: CardProps[] = moviesFromCategory.concat(seriesFromCategory)
    return results
  } else {
    return []
  }
}
