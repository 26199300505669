import './Login.scss'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { emailLoginPattern, onBackKeyPress } from '@utils/helpers'
import { auth } from '../../firebase'
import { fetchSignInMethodsForEmail } from 'firebase/auth'
import { login } from '@utils/auth'
import { setProfileButtonFocus } from '@actions/profileActions'
import { useDispatch } from 'react-redux'
import { loginRoutes, routes } from '@constants/routes.const'
import { LoginInputField } from '@components/Login/LoginInputField/LoginInputField'
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton'
import { Header } from '@components/Header/Header'
import DotSpinner from '@components/DotSpinner/DotSpinner'
import { KeyName } from '@constants/index'
import { useTypedSelector } from 'store'
import { ShowPassword } from '@components/ShowPassword/ShowPassword'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import { setPageNotFound } from '@actions/pageNotFoundActions'

interface LoginProps {
  showMenu: (status: boolean) => void
}

export const Login = ({ showMenu }: LoginProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  const showPasswordRef = useRef<HTMLFormElement>(null)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [shownField, setShownField] = useState('email')
  const [loading, setLoading] = useState(false)
  const V = useTypedSelector((state) => state.vocabulary.data)
  const initialRoute = useTypedSelector((state) => state.menu.initialRoute)

  const closeLoginScreen = useCallback(
    (e: KeyboardEvent) => {
      onBackKeyPress(e, () => {
        navigate(routes.INITIAL_PAGE)
      })
    },
    [navigate],
  )

  const handleLogin = useCallback(() => {
    dispatch(setPageNotFound(false))
    setLoading(true)
    login(auth, userEmail, userPassword)
      .then(() => {
        setLoading(false)
        initialRoute && navigate(initialRoute)
      })
      .catch(() => {
        setLoading(false)
        navigate(`${routes.LOGIN}/${loginRoutes.ACCOUNT_ERROR}`)
      })
  }, [userEmail, userPassword, dispatch, initialRoute, navigate])

  const checkEmail = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.preventDefault()

      try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, userEmail)

        if (signInMethods.length === 0 && emailLoginPattern.test(userEmail)) {
          setEmailErrorMessage(
            "Sorry, we couldn’t find an account with this e-mail. Check for typo. If you're new, register on the website.",
          )
        } else if (emailLoginPattern.test(userEmail)) {
          setShownField('password')
          setEmailErrorMessage(null)
        }
      } catch (error) {
        console.error('Error fetching sign-in methods:', error)
      }

      if (emailLoginPattern.test(userEmail) === false) {
        setEmailErrorMessage('The email format is wrong')
      }
    },
    [userEmail],
  )

  const checkPassword = useCallback(
    (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.preventDefault()

      if (userPassword.length > 0) {
        handleLogin()
        setUserEmail('')
        setUserPassword('')
        setPasswordErrorMessage(null)
      }
    },
    [handleLogin, userPassword.length],
  )

  const handleUserEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value)
  }

  const handleUserPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPassword(e.target.value)
  }

  const navigateToForgotPassword = useCallback(() => {
    navigate(`${routes.LOGIN}/${loginRoutes.FORGOT_PASSWORD}`)
  }, [navigate])

  const navigateToPinAndPair = useCallback(() => {
    navigate(`${routes.LOGIN}/${loginRoutes.PIN_AND_PAIR}`)
  }, [navigate])

  const navigateToShowPassword = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (shownField === 'password' && e.key === KeyName.ArrowRight) {
        if (inputRef.current?.value.length === inputRef.current?.selectionEnd) {
          inputRef.current?.blur()
          showPasswordRef.current?.focus()
        }
      }
    },
    [shownField],
  )

  useEffect(() => {
    const currentInputRef = inputRef?.current
    currentInputRef?.focus()
  }, [])

  useEffect(() => {
    showMenu(false)
    dispatch(setProfileButtonFocus(V.infoProfileLabel))

    return () => {
      showMenu(true)
    }
  }, [showMenu, dispatch, V.infoProfileLabel])

  useEffect(() => {
    document.addEventListener('keydown', closeLoginScreen, false)
    return () => document.removeEventListener('keydown', closeLoginScreen, false)
  })

  const renderShownField = useMemo(() => {
    switch (shownField) {
      case 'email':
        return (
          <div className="login__email-input">
            <LoginInputField
              type="text"
              label="Enter your e-mail"
              inputValue={userEmail}
              errorMessage={emailErrorMessage}
              onChange={handleUserEmail}
              onSubmit={checkEmail}
              inputRef={inputRef}
              placeholder="E-mail"
            />
            <div className="login__button-container">
              <SecondaryButton onClick={checkEmail}>Continue</SecondaryButton>
              <Text tag={TextTag.P}>or If you prefer you can logIn on this TV by browser.</Text>
              <SecondaryButton onClick={navigateToPinAndPair}>LogIn with browser</SecondaryButton>
            </div>
          </div>
        )
      case 'password':
        return (
          <div className="login__password-input">
            <div className="login__wrapper">
              <LoginInputField
                type={showPassword ? 'text' : 'password'}
                label="Enter your password"
                inputValue={userPassword}
                errorMessage={passwordErrorMessage}
                onChange={handleUserPassword}
                onSubmit={checkPassword}
                placeholder="Password"
                inputRef={inputRef}
                navigateToShowPassword={navigateToShowPassword}
              />
              <ShowPassword setShowPassword={setShowPassword} showPassword={showPassword} ref={showPasswordRef} />
            </div>
            <div className="login__button-container">
              <SecondaryButton onClick={checkPassword}>Continue</SecondaryButton>
              <SecondaryButton onClick={navigateToForgotPassword}>Forgot Password?</SecondaryButton>
            </div>
          </div>
        )
      default:
        return null
    }
  }, [
    checkEmail,
    checkPassword,
    emailErrorMessage,
    navigateToForgotPassword,
    navigateToPinAndPair,
    navigateToShowPassword,
    passwordErrorMessage,
    showPassword,
    shownField,
    userEmail,
    userPassword,
  ])

  const navigateBackToEmailSection = (e: KeyboardEvent) =>
    onBackKeyPress(e, () => {
      setShownField('email')
    })

  useEffect(() => {
    document.addEventListener('keydown', navigateBackToEmailSection, false)

    return () => document.removeEventListener('keydown', navigateBackToEmailSection, false)
  })

  return (
    <div className="login">
      <Header label="LogIn" />
      {loading ? <DotSpinner /> : renderShownField}
    </div>
  )
}
