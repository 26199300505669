import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface PinAndPairStepProps {
  stepNumber: string
  label: string
  value?: string
  valueClassName?: string
}

export const PinAndPairStep = ({ stepNumber, label, value, valueClassName }: PinAndPairStepProps) => {
  return (
    <div className="pin-and-pair-step">
      <Text tag={TextTag.P} className="pin-and-pair-step-badge">
        {stepNumber}
      </Text>
      <div className="pin-and-pair-step-info">
        <Text tag={TextTag.P} className="pin-and-pair-step-label">
          {label}
        </Text>
        {value && (
          <Text tag={TextTag.P} className={`pin-and-pair-step-value ${valueClassName}`}>
            {value}
          </Text>
        )}
      </div>
    </div>
  )
}
