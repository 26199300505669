import { SKELETON_CARD_POSITIONS, SKELETON_CARD_TYPES } from '@constants/skeleton.const'
import CardSkeleton from '@components/Skeleton/CardSkeleton/CardSkeleton'
import './RailSkeleton.scss'

interface RailSkeletonProps {
  itemsType: (typeof SKELETON_CARD_TYPES)[number]
  itemsPosition: (typeof SKELETON_CARD_POSITIONS)[number]
  itemsCount: number
}

const RailSkeleton = ({ itemsType, itemsPosition, itemsCount }: RailSkeletonProps) => {
  return (
    <div className="rail-skeleton-wrapper">
      {Array.from({ length: itemsCount }, (_, index) => (
        <CardSkeleton key={index} type={itemsType} position={itemsPosition} />
      ))}
    </div>
  )
}

export default RailSkeleton
