import React, { useCallback, useState } from 'react'
import './ActionButton.scss'
import { setIcon } from '@components/Buttons/icons'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import classNames from 'classnames'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

export interface ActionButtonProps {
  onFocusDownId?: string
  text?: string
  movieTitle?: string
  color?: string
  icon?: string
  className?: string
  isTop?: boolean
  fromButton?: boolean
  id?: string
  onClick: () => void
}

const ActionButton = ({ onFocusDownId, icon, text, isTop, fromButton, onClick, className, id }: ActionButtonProps) => {
  const [isFocus, setIsFocus] = useState(false)

  const handleButtonPress = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      onEnterKeyPressAndClick(e, onClick)
    },
    [onClick],
  )

  return (
    <button
      className={classNames('action-button focusable', { active: isFocus }, className)}
      data-sn-right={fromButton && ''}
      data-sn-up={isTop ? '' : null}
      data-sn-down={onFocusDownId}
      tabIndex={-1}
      onClick={handleButtonPress}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      id={id}
    >
      {icon && (
        <Text tag={TextTag.SPAN} className="action-button-icon">
          {setIcon(icon)}
        </Text>
      )}
      <Text tag={TextTag.SPAN} className="action-button-text">
        {text}
      </Text>
    </button>
  )
}

export default ActionButton
