import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from 'firebase/firestore'
import { db } from '../firebase'
import { FirebaseDocumentData, MyListCard } from '@interfaces/index'

export const addItemToMyList = async (uid: string, item: MyListCard) => {
  const favoritesCollectionRef = doc(db, 'accounts', uid, 'favorites', item.id)
  return await setDoc(favoritesCollectionRef, item)
}

export const removeItemFromMyList = async (uid: string, itemId: string) => {
  const accountsRef = collection(db, 'accounts')
  const userDocRef = doc(accountsRef, uid)
  const favoritesCollRef = collection(userDocRef, 'favorites')
  const itemDocRef = doc(favoritesCollRef, itemId)
  return await deleteDoc(itemDocRef)
}

export const getMyListItemById = async (uid: string, itemId: string) => {
  if (uid && itemId && db) {
    const docRef = doc(collection(db, 'accounts', uid, 'favorites'), itemId)
    const docSnap = await getDoc(docRef)
    return docSnap.exists()
  }
}

export const getAllMyListItems = async (uid: string) => {
  const favoritesCollectionRef = collection(doc(db, 'accounts', uid), 'favorites')
  const favoritesSnapshot = await getDocs(favoritesCollectionRef)
  return favoritesSnapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData> | FirebaseDocumentData) => doc.data())
}
