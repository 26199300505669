import React, { useEffect, useRef } from 'react'
import './InitialScreen.scss'
import { VelvetLogo } from '@assets/index'
import InitialScreenContent from '@components/InitialScreen/InitialScreenContent/InitialScreenContent'
import { useTypedSelector } from 'store'
import Image from '@components/Image/Image'

interface InitialScreenProps {
  showMenu: (status: boolean) => void
}

const InitialScreen = ({ showMenu }: InitialScreenProps) => {
  const loginButtonRef = useRef<HTMLButtonElement>(null)
  const config = useTypedSelector((state) => state.config.data)
  const background = config?.welcomeBackgroundImage

  useEffect(() => {
    showMenu(false)
    loginButtonRef.current?.focus()
  }, [showMenu])

  return (
    <div className="initial-screen">
      <div className="background-img">
        <Image image={background} alt="Velvet background" />
      </div>
      <VelvetLogo />
      <InitialScreenContent focusedBtn={loginButtonRef} />
    </div>
  )
}

export default InitialScreen
