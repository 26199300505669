import React from 'react'
import { Section } from '@interfaces/index'
import { Hero } from '@components/Hero/MovieHero/MovieHero'
import Rail from '@components/Rails/Rail/Rail'
import { HeroMoviesFullWidth } from '@components/HeroMoviesFullWidth/HeroMoviesFullWidth'

const Components = {
  'vel:displaytype:simplehero': Hero,
  'vel:displaytype:rail': Rail,
  'vel:displaytype:carouselhero': HeroMoviesFullWidth,
}
export type rowTypesType = Record<string, string>

export const RowTypes = {
  VERTICAL: 'verticalRow',
  HORIZONTAL: 'horizontalRow',
  SEARCH: 'searchRow',
  MYLIST: 'myList',
  EPISODES: 'episode',
}

const renderSection = (section: Section) => {
  //dynamically render component according to type
  if (section.template === 'vel:displaytype:simplehero') {
    return React.createElement(Components[section.template], {
      index: section?.index,
      key: section?.items[0].id,
      id: section?.items[0].id,
      title: section?.items[0].title,
      subtitle: section?.items[0].subtitle,
      description: section?.items[0].description,
      duration: section?.items[0].contextData?.duration,
      quality: section?.items[0].contextData?.quality,
      ageRating: section?.items[0].contextData?.ageRating,
      releaseDate: section?.items[0].contextData?.releaseDate,
      genres: section?.items[0].contextData?.genres,
      image: section?.items[0].contextData?.imageBackdrop,
      imageCard: section?.items[0].contextData?.imageCard,
      imageTitle: section?.items[0].contextData?.imageTitle,
      callToActions: section?.items[0].callToActions,
      railId: section?.id,
    })
  } else if (section.template === 'vel:displaytype:rail') {
    return React.createElement(Components[section.template], {
      section: section,
    })
  } else if (section.template === 'vel:displaytype:carouselhero') {
    return React.createElement(Components[section.template], {
      section: section,
    })
  }
  return null
}
export default renderSection
