import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setProfileButtonFocus } from '@actions/profileActions'
import { auth } from '../../../firebase'
import { logout } from '@utils/auth'
import { onBackKeyPress } from '@utils/helpers'
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton'
import DotSpinner from '@components/DotSpinner/DotSpinner'
import { ProfileTitle } from '@components/Profile/ProfileTitle/ProfileTitle'
import './Logout.scss'
import { routes } from '@constants/routes.const'
import { useTypedSelector } from 'store'

interface LogoutProps {
  showMenu: (status: boolean) => void
}

export const Logout = ({ showMenu }: LogoutProps) => {
  const logoutButtonRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const V = useTypedSelector((state) => state.vocabulary.data)

  useEffect(() => {
    showMenu(false)
    logoutButtonRef.current?.focus()
    dispatch(setProfileButtonFocus(V.profilePreferencesLogOut))
  }, [showMenu, dispatch, V.profilePreferencesLogOut])

  useEffect(() => {
    document.addEventListener('keydown', closeLogOutPage, false)

    return () => document.removeEventListener('keydown', closeLogOutPage, false)
  })

  const handleLogout = useCallback(() => {
    setLoading(true)
    sessionStorage.clear()
    logout(auth)
      .then(() => {
        setLoading(false)
        navigate(routes.LOGIN)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [navigate])

  const closeLogOutPage = useCallback(
    (e: KeyboardEvent) => {
      onBackKeyPress(e, () => {
        navigate(-1)
      })
    },
    [navigate],
  )

  const navigateToProfile = useCallback(() => {
    navigate(routes.PROFILE)
  }, [navigate])

  return (
    <div className="logout-page">
      {loading ? (
        <DotSpinner />
      ) : (
        <div className="logout-container">
          <ProfileTitle title={V.logOutProfileTitle} />
          <SecondaryButton onClick={handleLogout}>{V.logoutButtonLabel}</SecondaryButton>
          <SecondaryButton onClick={navigateToProfile} buttonRef={logoutButtonRef}>
            {V.cancelButtonLabel}
          </SecondaryButton>
        </div>
      )}
    </div>
  )
}
