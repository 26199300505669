import axios from 'axios'

export const fetchLoginCode = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/firebaseauth/pap/code`)
    return response.data
}


export const fetchCustomToken = async (code: number | undefined) => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/firebaseauth/pap/token?code=${code}`)
    return response
}