export const SET_ELEMENTS = "SET_ELEMENTS";
export const SET_MESSAGE = "SET_MESSAGE";

export const setElements = () => ({
  type: SET_ELEMENTS,
});

export const setMessage = (message: string) => ({
  type: SET_MESSAGE,
  payload: {
    message,
  },
});
