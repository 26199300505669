export const routes = {
  LOGIN: '/login',
  REGISTER: '/register',
  SEARCH: '/search',
  HOMEPAGE: '/home',
  CATEGORY: '/category',
  MOVIES: '/movie',
  SERIES: '/serie',
  MY_LIST: '/my-list',
  INITIAL_PAGE: '/initial-page',
  HOME: '/home',
  PROFILE: '/profile',
  PLAYER: '/player',
  PAGE_NOT_FOUND: '/page-404',
}

export const loginRoutes = {
  FORGOT_PASSWORD: 'forgot-password',
  PIN_AND_PAIR: 'pin-and-pair',
  ACCOUNT_ERROR: 'account-error',
}

export const profileRoutes = {
  PROFILE_INFO: 'profile-info',
  LEGAL: 'legal',
  LOGOUT: 'logout',
  EDIT_LANG: 'edit-language',
  EDIT_NAME: 'edit-name',
}

export const focusableRoutes = {
  CATEGORY: 'category',
  PROFILE: 'profile',
  MENU: 'menu',
}

export const mainRoutes = {
  HOME: 'home',
  MOVIE: 'movie',
  SERIES: 'serie',
}
