import { useCallback, useEffect, useRef } from 'react';

const useCarousel = (currentCarouselRef: HTMLDivElement | null ,moveLeft: () => void, moveRight: () => void, currentIndex: number) => {
  const keyPressed = useRef(false);
  const keyPressedTimeout = useRef<number>();
  const keyPressedTimeoutMilliseconds = 250;

  const moveCarousel = useCallback(
    (e: KeyboardEvent) => {
      if (!keyPressed.current) {
        if (e.key === 'ArrowRight') {
          moveRight();
        } else if (e.key === 'ArrowLeft') {
          moveLeft();
        }
        keyPressed.current = true;

        if (keyPressedTimeout.current) {
          clearTimeout(keyPressedTimeout.current);
        }

        keyPressedTimeout.current = window.setTimeout(() => {
          keyPressed.current = false;
        }, keyPressedTimeoutMilliseconds);
      }
    },
    [moveRight, moveLeft],
  );

  const clearTimeoutOnKeyUp = useCallback(() => {
    if (keyPressedTimeout.current) {
      clearTimeout(keyPressedTimeout.current)
    }
    keyPressed.current = false
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => moveCarousel(e);

    currentCarouselRef?.addEventListener('keydown', handleKeyDown, false);
    currentCarouselRef?.addEventListener('keyup', clearTimeoutOnKeyUp, false);

    return () => {
      currentCarouselRef?.removeEventListener('keydown', handleKeyDown, false);
      currentCarouselRef?.removeEventListener('keyup', clearTimeoutOnKeyUp, false);
    };
  }, [currentCarouselRef, moveCarousel, clearTimeoutOnKeyUp, currentIndex]);
};

export default useCarousel;
