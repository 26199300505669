import { SET_SKELETON } from '@actions/skeletonActions'

interface SkeletonAction {
  type: string
  payload: {
    show: boolean
  }
}

const initialState = {
  show: false,
}

export default function skeletonReducer(state = initialState, action: SkeletonAction) {
  switch (action.type) {
    case SET_SKELETON:
      return {
        ...state,
        show: action.payload.show,
      }
    default:
      return state
  }
}
