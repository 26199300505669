import React from 'react'
import './TabsSkeleton.scss'

const TabsSkeleton = () => {
  return (
    <div className="tabs-skeleton-container">
      <div className="skeleton-element tab-skeleton"></div>
      <div className="skeleton-element tab-skeleton"></div>
      <div className="skeleton-element tab-skeleton"></div>
    </div>
  )
}

export default TabsSkeleton
