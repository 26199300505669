export const platformType = {
  CTV: 'ctv',
  WEB: 'web',
  ANDROID: 'android',
  ANDROID_TV: 'androidtv',
  IOS: 'ios',
  APPLE_TV: 'appletv',
}

export const subscriptionPlanType = {
  FREE: 'free',
  BASIC: 'basic',
  PRO: 'pro',
  PRO_PLUS: 'proplus',
}
