import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { sectionsReducer } from '@reducers/sectionsReducer'
import rentOrBuyReducer from '@reducers/rentOrBuyReducer'
import { movieDetailReducer } from '@reducers/detailsReducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import profileReducer from '@reducers/profileReducer'
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import menuReducer from '@reducers/menuReducer'
import configReducer from '@reducers/configReducer'
import pageReducer from '@reducers/pageReducer'
import skeletonReducer from '@reducers/skeletonReducer'
import { vocabularyReducer } from '@reducers/vocabularyReducer'
import playerReducer from '@reducers/playerReducer'
import pageNotFoundReducer from '@reducers/pageNotFoundReducer'

const rootReducer = combineReducers({
  config: configReducer,
  sections: sectionsReducer,
  movie: movieDetailReducer,
  rentOrBuy: rentOrBuyReducer,
  profile: profileReducer,
  page: pageReducer,
  skeleton: skeletonReducer,
  menu: menuReducer,
  vocabulary: vocabularyReducer,
  player: playerReducer,
  pageNotFound: pageNotFoundReducer,
})
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export type RootState = ReturnType<typeof rootReducer>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
