import './LoginInputField.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorExclamationIcon } from '@assets/index'
import { KeyName } from '@constants/index'
import { loginRoutes, routes } from '@constants/routes.const'
import Input from '@components/Input/Input'
import { moveInputCursor } from '@utils/helpers'

type LoginInputFieldProps = {
  type: string
  label: string
  sublabel?: string | React.ReactNode
  inputValue: string
  errorMessage: string | null
  isSubmitted?: boolean
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  inputRef: React.RefObject<HTMLInputElement>
  navigateToShowPassword?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const LoginInputField = ({
  type,
  label,
  sublabel,
  inputValue,
  errorMessage,
  isSubmitted,
  onChange,
  onSubmit,
  placeholder,
  inputRef,
  navigateToShowPassword,
}: LoginInputFieldProps) => {
  const [focusInput, setFocusInput] = useState(true)

  const formRef = useRef<HTMLFormElement>(null)

  const location = useLocation()

  useEffect(() => {
    if (focusInput) {
      inputRef.current?.focus()
    }
    if (inputRef && inputRef.current && !focusInput) {
      inputRef.current.selectionStart = inputRef.current.selectionEnd = inputValue.length
    }
  }, [focusInput, inputValue.length, inputRef])

  const navigateFromKeyboard = (e: React.KeyboardEvent<HTMLInputElement>) => {
    navigateToShowPassword && navigateToShowPassword(e)
    if (e.key === KeyName.ArrowDown) {
      if (location.pathname === `${routes.LOGIN}/${loginRoutes.FORGOT_PASSWORD}`) {
        formRef.current ? formRef.current?.focus() : inputRef.current?.focus()
      } else formRef.current?.focus()
    } else if (e.key === KeyName.ArrowUp) {
      e.preventDefault()
    } else if (e.key === KeyName.ArrowLeft) {
      moveInputCursor('left', inputRef.current)
      e.preventDefault()
    } else if (e.key === KeyName.ArrowRight) {
      moveInputCursor('right', inputRef.current)
      e.preventDefault()
    }
  }

  return (
    <div className="login-input">
      <div className="login-input__label">{label}</div>
      {sublabel && <div className="login-input__sublabel">{sublabel}</div>}
      {!isSubmitted && (
        <form
          onSubmit={onSubmit}
          className="focusable"
          ref={formRef}
          tabIndex={-1}
          onFocus={() => setFocusInput(true)}
          onBlur={() => setFocusInput(false)}
        >
          <Input
            inputRef={inputRef}
            type={type}
            value={inputValue}
            onChange={onChange}
            onKeyDown={(e) => navigateFromKeyboard(e)}
            className={errorMessage ? 'login-input__error-border' : ''}
            placeholder={placeholder}
          />
          {errorMessage && (
            <div className="login-input__error-container">
              <ErrorExclamationIcon />
              <div className="login-input__error-message">{errorMessage}</div>
            </div>
          )}
        </form>
      )}
    </div>
  )
}
