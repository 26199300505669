import axios from 'axios'
import { Dispatch } from 'redux'
import { Config } from '@interfaces/index'
import { Action } from '@reducers/configReducer'

export const FETCH_CONFIG_REQUEST = 'FETCH_CONFIG_REQUEST' as const
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS' as const
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE' as const

export const fetchConfigRequest = () => ({ type: FETCH_CONFIG_REQUEST })
export const fetchConfigSuccess = (config: Config) => ({
  type: FETCH_CONFIG_SUCCESS,
  payload: config,
})
export const fetchConfigFailure = (error: string) => ({
  type: FETCH_CONFIG_FAILURE,
  payload: error,
})

export const fetchConfig = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(fetchConfigRequest())
    axios
      .get(process.env.REACT_APP_BASE_URL + '/parsed/config/ctv')
      .then((res) => {
        const config = res.data
        dispatch(fetchConfigSuccess(config))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(fetchConfigFailure(errorMsg))
      })
  }
}
