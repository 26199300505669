import CardSkeleton from '@components/Skeleton/CardSkeleton/CardSkeleton'
import './GridSkeleton.scss'

const GridSkeleton = () => {
  return (
    <div className="my-list-skeleton-wrapper">
      <div className="skeleton-element my-list-skeleton-page-title"></div>
      <div className="my-list-skeleton-content">
        {Array.from({ length: 20 }, (_, index) => (
          <CardSkeleton key={index} type="movie" position="horizontal" title description />
        ))}
      </div>
    </div>
  )
}

export default GridSkeleton
