import { Tick } from '@assets/index'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import React, { RefObject } from 'react'

interface ModalButtonProps {
  id: string
  buttonClassName: string
  buttonRef?: RefObject<HTMLDivElement> | null
  onFocus: (e: React.FocusEvent) => void
  onKeyDown: (e: React.KeyboardEvent) => void
  onClick: (e: React.MouseEvent) => void
  label: string
  labelClassName: string
  onFocusDown?: string
}

const ModalButton = ({
  id,
  buttonClassName,
  onFocus,
  onKeyDown,
  label,
  labelClassName,
  buttonRef,
  onFocusDown,
  onClick,
}: ModalButtonProps) => {
  return (
    <div
      id={id}
      className={`focusable ${buttonClassName}`}
      tabIndex={-1}
      onFocus={(e) => onFocus(e)}
      onKeyDown={(e) => onKeyDown(e)}
      onClick={(e) => onClick(e)}
      ref={buttonRef}
      data-sn-down={onFocusDown}
    >
      <Text tag={TextTag.P} className={`innerText ${labelClassName}`}>
        <Tick />
        <Text tag={TextTag.SPAN}>{label}</Text>
      </Text>
    </div>
  )
}

export default ModalButton
