import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { onEnterKeyPressAndClick, openMenuOnBackKey } from '@utils/helpers'
import { useTypedSelector } from 'store'
import './Profile.scss'
import { ProfileButton } from '@components/Profile/ProfileButton/ProfileButton'
import { ProfileTitle } from '@components/Profile/ProfileTitle/ProfileTitle'
import { profileRoutes, routes } from '@constants/routes.const'
import { profileType } from '@constants/profile.const'
import { setProfileButtonFocus } from '@actions/profileActions'
import { useDispatch } from 'react-redux'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import packageJSON from '../../../package.json'

interface ProfileProps {
  showMenu: (status: boolean) => void
}

export const Profile = ({ showMenu }: ProfileProps) => {
  const profileButtonRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { profile, vocabulary, menu } = useTypedSelector((state) => state)
  const { focusedButton } = profile
  const { data: V } = vocabulary
  const { openMenu } = menu
  const version = V.releaseNumberLabel?.replace('%{numVersion}', packageJSON.version)

  const buttonData = [
    {
      buttonId: profileType.PROFILE,
      buttonText: V.infoProfileLabel,
      route: `${routes.PROFILE}/${profileRoutes.PROFILE_INFO}`,
    },
    {
      buttonId: 'legal-button',
      buttonText: V.profilePreferencesLegalTitle,
      route: `${routes.PROFILE}/${profileRoutes.LEGAL}`,
    },
    {
      buttonId: 'logout-button',
      buttonText: V.profilePreferencesLogOut,
      route: `${routes.PROFILE}/${profileRoutes.LOGOUT}`,
      onFocusDown: '',
    },
  ]

  const handleNavigation = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent, route: string) => {
      e.preventDefault()
      onEnterKeyPressAndClick(e, () => navigate(route))
    },
    [navigate],
  )

  const onFocus = useCallback(
    (e: React.FocusEvent, focusableText: string): void => {
      e.preventDefault()
      dispatch(setProfileButtonFocus(focusableText))
    },
    [dispatch],
  )

  useEffect(() => {
    showMenu(true)
  }, [showMenu])

  useEffect(() => {
    !openMenu && profileButtonRef.current?.focus()
  }, [openMenu])

  useEffect(() => {
    document.addEventListener('keydown', openMenuOnBackKey)

    return () => document.removeEventListener('keydown', openMenuOnBackKey)
  }, [])

  return (
    <div className="profile-container">
      <ProfileTitle title={V.infoProfileLabel} />
      <div className="profile-buttons-container">
        {buttonData.map(({ buttonId, buttonText, route, onFocusDown }, idx) => {
          return (
            <ProfileButton
              key={buttonId}
              buttonType="large"
              buttonId={buttonId}
              buttonText={buttonText}
              onFocusEvent={(e) => onFocus(e, buttonText)}
              onKeyDownEvent={(e) => handleNavigation(e, route)}
              onClick={(e) => handleNavigation(e, route)}
              buttonRef={idx === 0 || focusedButton === buttonText ? profileButtonRef : null}
              onFocusDown={onFocusDown}
            />
          )
        })}
      </div>
      <Text tag={TextTag.P} className="release-version">
        {version ?? packageJSON.version}
      </Text>
    </div>
  )
}
