import { ScrollBehavior } from '../constants'
import SpatialNavigation from '@utils/spatial-navigation.js'
import { MutableRefObject } from 'react'
import Scroll from 'react-scroll'

/**
 * Spatial Navigation initial logic
 */
export const initializeSpatialNavigation = () => {
  const SN = SpatialNavigation
  // Initialize
  SN.init()
  // Add the focusable class to the selector so we can use
  // the arrow keys to move throughout the elements
  const focusableElements = [
    { id: 'player-modal-focus', selector: '.player-modal-focus', restrict: 'self-only' },
    { id: 'my-list', selector: '.my-list' },
    { id: 'category', selector: '.category-page' },
    { id: 'app-content', selector: '.app-content' },
  ]

  focusableElements.forEach(({ id, selector, restrict }) => {
    if (!SN.exist(id)) {
      SN.add(id, {
        selector: `${selector} .focusable`,
        enterTo: 'last-focused',
        restrict,
      })
    }
  })

  SN.add({ selector: '.focusable' })
}

const smoothScrollOptions = {
  duration: 350,
  isDynamic: true,
  smooth: true,
  ignoreCancelEvents: true,
}

const instantScrollOptions = {
  duration: 0,
  isDynamic: true,
  smooth: false,
  ignoreCancelEvents: true,
}

export const scrollToTop = (behavior: ScrollBehavior = 'smooth') => {
  const scroll = Scroll.animateScroll

  scroll.scrollToTop({ behavior })
}

export const scrollTo = (behavior: ScrollBehavior = 'smooth', toY: number) => {
  const scroll = Scroll.animateScroll

  scroll.scrollTo(toY, { behavior })
}

export const scrollScreen = (focusedElement: Event) => {
  const scroll = Scroll.animateScroll
  const focusedElementTarget = focusedElement?.target as HTMLElement
  const targetScrollTop = focusedElementTarget.offsetTop - (window.innerHeight - focusedElementTarget.offsetHeight) / 2
  const hasPreviousDirection = !!(focusedElement as CustomEvent).detail.direction
  if (focusedElementTarget.classList.contains('scroll-center')) {
    scroll.scrollTo(targetScrollTop, hasPreviousDirection ? smoothScrollOptions : instantScrollOptions)
  } else if (focusedElementTarget.classList.contains('scroll-top')) {
    scroll.scrollToTop(smoothScrollOptions)
  }
}

/**
 * Detect if wheel event is fired by trackpad/touchpad
 * @param evt wheel event
 * @returns boolean
 */
export const isTrackpad = (evt: WheelEvent): boolean => {
  const { deltaY } = evt
  if (deltaY && !Number.isInteger(deltaY)) {
    return false
  }
  return true
}

/**
 * Logic to move up or down depending of wheel scroll (Magic Mouse LG)
 * @param evt wheel event when the user do scroll
 * @param keyPressed ref used for debounce
 * @param keyPressedTimeout ref used for debounce
 */
export const scrollNavigate = (
  evt: WheelEvent,
  keyPressed: MutableRefObject<boolean>,
  keyPressedTimeout: MutableRefObject<number>,
) => {
  evt.preventDefault()
  const SN = SpatialNavigation
  const { deltaY } = evt
  let TIMEOUT_TIME = 200

  if (isTrackpad(evt)) {
    TIMEOUT_TIME = 0
  }

  if (deltaY && !keyPressed.current) {
    if (deltaY < 0) {
      SN.move('up')
    } else {
      SN.move('down')
    }
  }
  keyPressed.current = true

  keyPressedTimeout.current && clearTimeout(keyPressedTimeout.current)

  keyPressedTimeout.current = window.setTimeout(() => {
    keyPressed.current = false
  }, TIMEOUT_TIME)
}
