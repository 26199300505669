import { SET_PAGE_NOT_FOUND } from '@actions/pageNotFoundActions'

interface PageNotFoundAction {
  type: string
  payload: {
    show: boolean
  }
}

const initialState = {
  show: false,
}

export default function pageReducer(state = initialState, action: PageNotFoundAction) {
  switch (action.type) {
    case SET_PAGE_NOT_FOUND:
      return {
        ...state,
        show: action.payload.show,
      }

    default:
      return state
  }
}
