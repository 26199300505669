import { Section } from '@interfaces/index'
import {
  FetchSectionsFailure,
  FetchSectionsInit,
  FetchSectionsRequest,
  FetchSectionsSuccess,
} from '@actions-types/sectionsActionsTypes'
import { FETCH_SECTIONS_FAILURE, FETCH_SECTIONS_SUCCESS, FETCH_SECTIONS_REQUEST } from '@actions/sectionsActions'

const initialState = {
  loadingSections: 0,
  sections: undefined,
  error: '',
}

interface State {
  loadingSections: number
  sections?: Section[]
  error: string
}

const actionInit = {
  type: '',
  payload: undefined,
}

export type Action = FetchSectionsRequest | FetchSectionsSuccess | FetchSectionsFailure | FetchSectionsInit

export const sectionsReducer = (state: State = initialState, action: Action = actionInit): State => {
  switch (action.type) {
    case FETCH_SECTIONS_REQUEST:
      return {
        ...state,
        loadingSections: state.loadingSections + 1,
      }
    case FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        loadingSections: state.loadingSections - 1,
        sections: (action as FetchSectionsSuccess).payload,
        error: '',
      }
    case FETCH_SECTIONS_FAILURE:
      return {
        ...state,
        loadingSections: 0,
        sections: [],
        error: (action as FetchSectionsFailure).payload,
      }
    default:
      return state
  }
}

export default sectionsReducer
