import './RegisterStep.scss'
import Tag from '@components/Tag/Tag'
import { tagTypes } from '@constants/element-types.const'

interface RegisterStepProps {
  label: string
  text: string
}

const RegisterStep = ({ label, text }: RegisterStepProps) => {
  return (
    <div className="step">
      <Tag type={tagTypes.STEP} label={label} />
      <div className="step__text">{text}</div>
    </div>
  )
}

export default RegisterStep
