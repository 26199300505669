import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './AccountError.scss'
import { loginRoutes, routes } from '@constants/routes.const'
import { Header } from '@components/Header/Header'
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface AccountErrorProps {
  showMenu: (status: boolean) => void
}

export const AccountError = ({ showMenu }: AccountErrorProps) => {
  const tryAgainButtonRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    showMenu(false)
    tryAgainButtonRef?.current?.focus()

    return () => {
      showMenu(true)
    }
  }, [tryAgainButtonRef, showMenu])

  const tryAgainClicked = useCallback(() => {
    navigate(routes.LOGIN)
  }, [navigate])

  const navigateToForgotPassword = useCallback(() => {
    navigate(`${routes.LOGIN}/${loginRoutes.FORGOT_PASSWORD}`)
  }, [navigate])

  return (
    <div className="account-error">
      <Header label="LogIn" />
      <div className="account-error">
        <div className="account-error__text">
          <Text tag={TextTag.P}>Sorry, we have problems with your login.</Text>
          <Text tag={TextTag.P}>Your e-mail or password is not correct.</Text>
        </div>
        <div className="account-error__button-container">
          <SecondaryButton onClick={tryAgainClicked} buttonRef={tryAgainButtonRef}>
            Try Again
          </SecondaryButton>
          <SecondaryButton onClick={navigateToForgotPassword}>Forgot Password?</SecondaryButton>
        </div>
      </div>
    </div>
  )
}
