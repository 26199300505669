import axios from 'axios'
import { Dispatch } from 'react'
import { Movie } from '@interfaces/index'
import { Language } from '@constants/index'
import { Action } from '@reducers/detailsReducer'

export const FETCH_MOVIE_REQUEST = 'FETCH_MOVIE_REQUEST' as const
export const FETCH_MOVIE_SUCCESS = 'FETCH_MOVIE_SUCCESS' as const
export const FETCH_MOVIE_FAILURE = 'FETCH_MOVIE_FAILURE' as const
export const RESET_MOVIE_DETAILS = 'RESET_MOVIE_DETAILS' as const

export const fetchMovieRequest = () => ({ type: FETCH_MOVIE_REQUEST })

export const fetchMovieSuccess = (movie: Movie) => ({
  type: FETCH_MOVIE_SUCCESS,
  payload: movie,
})

export const fetchMovieFailure = (error: string) => ({
  type: FETCH_MOVIE_FAILURE,
  payload: error,
})

export const fetchMovieDetails = (category: string | undefined, movieId: string | undefined) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(fetchMovieRequest())
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/parsed/${Language.EN}/resource/${category}/${movieId}`)
      .then((res) => {
        const movie: Movie = res.data
        dispatch(fetchMovieSuccess(movie))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(fetchMovieFailure(errorMsg))
      })
  }
}

export const resetMovieDetails = () => ({ type: RESET_MOVIE_DETAILS })
