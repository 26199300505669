import { SET_PROFILE_BUTTON_FOCUS } from '@actions/profileActions'

const initialState = {
  focusedButton: '',
}

export default function profileReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_PROFILE_BUTTON_FOCUS:
      return {
        ...state,
        focusedButton: action.payload.focusedButton,
      }
    default:
      return state
  }
}
