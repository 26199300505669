import { Language } from '@constants/index'
import { QueryParams } from '@services/dynamic-page'

interface AuthParams {
  email?: string | null
  uid?: string
  accessToken?: string
  refreshToken?: string
}

export const fetchToApi = async (
  url: string,
  method: string,
  queryParams?: QueryParams,
  authParams?: AuthParams,
  apiToken?: string,
  isAuth?: boolean,
  isLang?: boolean,
) => {
  const configPath = isAuth ? 'firebaseauth' : 'parsed'
  const language = isLang ? `/${Language.EN}` : ''
  let apiUrl = `${process.env.REACT_APP_BASE_URL}/${configPath}${language}/${url}`

  if (queryParams) {
    const queryString = Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&')

    if (queryString) {
      apiUrl += `?${queryString}`
    }
  }

  const response = await fetch(apiUrl, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: apiToken ? `Bearer ${apiToken}` : '',
    },
    body: authParams ? JSON.stringify(authParams) : undefined, // Only include body if authParams is provided
  })

  return await response.json()
}
