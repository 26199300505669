import React, { useCallback, LegacyRef } from 'react'
import './CTAButton.scss'
import { setIcon } from '@components/Buttons/icons'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import { focusableRoutes } from '@constants/routes.const'
import classNames from 'classnames'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

export interface CTAButtonProps {
  icon: string
  contentCategory: string
  buttonText: string
  isTop?: boolean
  onFocusDownId?: string
  onClick: () => void
  className?: string
  ctaButtonRef?: LegacyRef<HTMLButtonElement>
  id: string
}

const CTAButton = ({
  icon,
  buttonText,
  isTop,
  onFocusDownId,
  onClick,
  className,
  ctaButtonRef,
  id,
}: CTAButtonProps) => {
  const handleButtonPress = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      onEnterKeyPressAndClick(e, onClick)
    },
    [onClick],
  )

  return (
    <button
      id={`cta-btn-${id}`}
      data-sn-up={isTop ? '' : null}
      data-sn-down={onFocusDownId}
      className={classNames(`cta-button focusable`, className)}
      ref={ctaButtonRef}
      onClick={handleButtonPress}
      data-sn-left={`#${focusableRoutes.MENU}`}
    >
      <Text tag={TextTag.SPAN} className="cta-button-icon">
        {setIcon(icon)}
      </Text>
      <Text tag={TextTag.SPAN} className="cta-button-text">
        {buttonText}
      </Text>
    </button>
  )
}

export default CTAButton
