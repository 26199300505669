import { useCallback, useEffect, useRef, useState } from 'react'
import './Register.scss'
import { Header } from '@components/Header/Header'
import NavigationButton from '@components/Buttons/NavigationButton/NavigationButton'
import { onBackKeyPress } from '@utils/helpers'
import { useNavigate } from 'react-router-dom'
import Title from '@components/Title/Title'
import QRCode from 'react-qr-code'
import { useTypedSelector } from 'store'
import { titleTypes } from '@constants/element-types.const'
import RegisterStep from '@templates/RegisterStep/RegisterStep'
import { routes } from '@constants/routes.const'
import { ArrowLeftIcon } from '@assets/index'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface RegisterProps {
  showMenu: (status: boolean) => void
}

const Register = ({ showMenu }: RegisterProps) => {
  const navigate = useNavigate()
  const buttonBack = useRef<HTMLButtonElement>(null)
  const config = useTypedSelector((state) => state.config.data)
  const [qrUrl, setQrUrl] = useState('')

  const handleButtonClick = useCallback(() => {
    navigate(routes.INITIAL_PAGE)
  }, [navigate])

  const handleButtonBackClick = useCallback(
    (e: KeyboardEvent) =>
      onBackKeyPress(e, () => {
        navigate(routes.INITIAL_PAGE)
      }),
    [navigate],
  )

  useEffect(() => {
    showMenu(false)

    return () => {
      showMenu(true)
    }
  }, [showMenu])

  useEffect(() => {
    buttonBack?.current?.focus()
  }, [buttonBack])

  useEffect(() => {
    config && setQrUrl(config.webRegisterUrl)
  }, [config, qrUrl])

  useEffect(() => {
    document.addEventListener('keydown', handleButtonBackClick)

    return () => document.removeEventListener('keydown', handleButtonBackClick)
  }, [handleButtonBackClick])

  return (
    <div className="register">
      <Header
        element={
          <NavigationButton reference={buttonBack} tabIndex={-1} onClick={handleButtonClick}>
            <ArrowLeftIcon className="arrow-left-icon" />
            <Text tag={TextTag.SPAN}>Back</Text>
          </NavigationButton>
        }
      />
      <div className="register__content-container">
        <Title type={titleTypes.PAGE} title="Register" />
        <Title type={titleTypes.PAGE} title="Follow the steps to register" />
        <div className="register__steps-container">
          <RegisterStep label="Step 1" text="Scan the QR code or go to the website:" />
          <RegisterStep label="Step 2" text="Choose your plan and register" />
          <RegisterStep label="Step 3" text="Enjoy Velvet on any device!" />
        </div>
        <div className="register__qr-code-container">
          <QRCode className="register__qr-code" value={qrUrl} />
        </div>
        <Text tag={TextTag.P} className="register__link">
          {qrUrl}
        </Text>
      </div>
    </div>
  )
}

export default Register
