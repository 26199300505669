import React, { useCallback } from 'react'
import NavigationButton from '@components/Buttons/NavigationButton/NavigationButton'
import Title from '@components/Title/Title'
import { useNavigate } from 'react-router-dom'
import './EmptyListContainer.scss'
import { focusableRoutes, routes } from '@constants/routes.const'

interface EmptyListContainerProps {
  firstSubtitle: string
  secondSubtitle: string
  buttonTitle: string
}
const EmptyListContainer = ({ firstSubtitle, secondSubtitle, buttonTitle }: EmptyListContainerProps) => {
  const navigate = useNavigate()

  const navigateToHomePage = useCallback(() => {
    navigate(routes.HOME)
  }, [navigate])

  return (
    <div className="wrapper">
      <Title className="empty" title={firstSubtitle} />
      <Title className="addContent" title={secondSubtitle} />
      <div className="navigation-button__container">
        <NavigationButton
          onFocusLeft={`#${focusableRoutes.MENU}`}
          onFocusUp=""
          onFocusDown=""
          onClick={navigateToHomePage}
          tabIndex={-1}
        >
          {buttonTitle}
        </NavigationButton>
      </div>
    </div>
  )
}

export default EmptyListContainer
