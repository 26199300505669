import '@splidejs/react-splide/css'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchPage, fetchPageSuccess, resetPage } from '@actions/pageActions'
import Pages from 'pages'
import { setSkeleton } from '@actions/skeletonActions'
import { resetMovieDetails } from '@actions/movieDetailActions'
import { QueryParams, generatePageStructureByQueryParams } from '@services/dynamic-page'
import { platformType, subscriptionPlanType } from '@constants/subscription.const'
import { replaceBaseUrlAndLang } from '@utils/dynamic-page'
import { Language } from '@constants/index'
import { categoryType } from '@constants/category.const'
import { CardSessionStorage, RouteStructureData } from '@interfaces/index'
import SpatialNavigation from '@utils/spatial-navigation.js'
import { getLanguage } from '@utils/vocab'
import { setPageNotFound } from '@actions/pageNotFoundActions'
import { routes } from '@constants/routes.const'

interface DynamicPageProps {
  showMenu: (status: boolean) => void
}

export const DynamicPage = ({ showMenu }: DynamicPageProps) => {
  const userDataObjectLS = JSON.parse(localStorage.getItem('user') as string)
  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const subscriptionPlanLS = userDataObjectLS?.subscription
  const config = useTypedSelector((state) => state.config.data)
  const page = useTypedSelector((state) => state.page.data)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [currentPageStructure, setCurrentPageStructure] = useState<string | null | undefined>(null)
  const lastFocusedCardInSS: CardSessionStorage = JSON.parse(sessionStorage.getItem('lastFocusedCard-home') as string)
  const SN = SpatialNavigation
  const initialRoute = useTypedSelector((state) => state.menu.initialRoute)
  const error = useTypedSelector((state) => state.page.error)

  useEffect(() => {
    if (config && initialRoute) {
      dispatch(setSkeleton(true))

      const language = getLanguage()
      localStorage.setItem(`${userObject?.uid}`, JSON.stringify(language))

      const path = location.pathname.substring(1) // ex. /home -> home
      const fetchPath = path.split('/')[0] // getting the first param from the url ex. movies/1 => movies
      let routeDataValue: RouteStructureData | null

      switch (fetchPath) {
        case categoryType.MOVIE:
        case categoryType.SERIE:
          routeDataValue = config?.routeData['details']
          break
        case '': //initial start of the app, when there is no param from the url
          routeDataValue = null
          break
        default:
          routeDataValue = config?.routeData[fetchPath]
          break
      }

      const getStructureEndpoint = () => {
        switch (routeDataValue) {
          case routeDataValue:
            return (
              routeDataValue &&
              replaceBaseUrlAndLang(routeDataValue?.structure, process.env.REACT_APP_BASE_URL as string, Language.EN)
            )
          case null: //initial start of the app, when there is no param from the url
            return null
          case undefined: //when the endpoint doesn't exist
            return undefined
        }
      }

      const structureEndpoint = getStructureEndpoint()

      const queryParams: QueryParams = {
        platform: platformType.CTV,
        product: subscriptionPlanLS || subscriptionPlanType.FREE,
      }

      const structureEndpointWithQueryParams =
        structureEndpoint && generatePageStructureByQueryParams(structureEndpoint, queryParams)

      setCurrentPageStructure(structureEndpointWithQueryParams)

      switch (true) {
        case currentPageStructure === null:
          if (structureEndpoint) {
            dispatch(fetchPage(structureEndpointWithQueryParams))
          } else if (structureEndpoint === null) {
            //initial start of the app, when there is no param from the url
            navigate(routes.INITIAL_PAGE)
          } else if (structureEndpoint === undefined) {
            //when the endpoint doesn't exist
            dispatch(setSkeleton(false))
            dispatch(setPageNotFound(true))
          }
          break

        case currentPageStructure === structureEndpointWithQueryParams:
          //when the endpoint is the same as before. ex: if we are on Home, it should not fetch the same Home again if clicked on it.
          //it should fetch only if we are on the details page (movies/series) or if there is an error
          if (fetchPath === categoryType.MOVIE || fetchPath === categoryType.SERIE || error) {
            dispatch(fetchPage(structureEndpointWithQueryParams))
          } else {
            dispatch(setSkeleton(false))
            dispatch(fetchPageSuccess(page))
            SN.focus()
          }
          break

        case currentPageStructure !== structureEndpointWithQueryParams:
          dispatch(fetchPage(structureEndpointWithQueryParams))
          break
      }

      showMenu(true)
    }

    return () => {
      dispatch(setSkeleton(false))
      dispatch(resetPage())
      dispatch(resetMovieDetails())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, config, subscriptionPlanLS, navigate, location, initialRoute])

  useEffect(() => {
    if (page && lastFocusedCardInSS?.railId) {
      lastFocusedCardInSS.heroFocusOn && lastFocusedCardInSS.homeSlug === location.pathname
        ? SN.focus(`#${lastFocusedCardInSS.heroFocusOn}`)
        : SN.focus(`#${lastFocusedCardInSS?.railId}`)
    }
  }, [page, SN, lastFocusedCardInSS, location.pathname])

  return <Pages page={page} showMenu={showMenu} />
}
