import React, { RefObject } from 'react'
import { Movie } from '@interfaces/index'
import SpatialNavigation from '@utils/spatial-navigation.js'
import { KeyName, KeyCode } from '@constants/index'
import { moveInputCursor } from './helpers'

export const disableMoveDown = (index: number, array: Movie[]) => {
  let value
  if (array.length % 3 === 1) {
    value = index === array.length - 1
  } else if (array.length % 3 === 2) {
    value = index === array.length - 1 || index === array.length - 2
  } else if (array.length % 3 === 0) {
    value = index === array.length - 1 || index === array.length - 2 || index === array.length - 3
  }
  return value ? '' : undefined
}

export const disableMoveUp = (index: number) => {
  const indexValue = index === 0 || index === 1 || index === 2
  return indexValue ? '' : undefined
}

export const navigateSearchFromKeyboard = (
  event: React.KeyboardEvent<HTMLDivElement>,
  value: string,
  wrapperRef: RefObject<HTMLDivElement>,
  inputRef: RefObject<HTMLInputElement>,
) => {
  const SN = SpatialNavigation
  const backKeyPressed =
    event.key === KeyName.Escape || event.keyCode === KeyCode.TizenBackKey || event.keyCode === KeyCode.WebosBackKey
  const leftKeyPressed = event.key === KeyName.ArrowLeft
  const rightKeyPressed = event.key === KeyName.ArrowRight
  const downKeyPressed = event.key === KeyName.ArrowDown
  const upKeyPressed = event.key === KeyName.ArrowUp
  const enterKeyPressed = event.key === KeyName.Enter

  if (enterKeyPressed && value.length > 0) {
    wrapperRef?.current?.focus()
    SN.move('down')
  } else if (leftKeyPressed) {
    if (value.length === 0 || inputRef.current?.selectionStart === 0) {
      wrapperRef?.current?.focus()
      SN.move('left')
    } else {
      moveInputCursor('left', inputRef.current)
      event.preventDefault()
    }
  } else if (downKeyPressed) {
    if (value.length === 0) {
      event.preventDefault()
    } else {
      wrapperRef?.current?.focus()
    }
  } else if (upKeyPressed) {
    event.preventDefault()
  } else if (backKeyPressed) {
    wrapperRef?.current?.focus()
  } else if (rightKeyPressed) {
    if (inputRef.current?.value.length === inputRef.current?.selectionEnd) {
      wrapperRef?.current?.focus()
    } else {
      moveInputCursor('right', inputRef.current)
      event.preventDefault()
    }
  }
}
