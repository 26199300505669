import { SET_MESSAGE, SET_ELEMENTS } from '@actions/rentOrBuyActions'

const initialState = {
  showElements: false,
  message: '',
}

export default function rentOrBuyReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_ELEMENTS:
      return {
        ...state,
        showElements: !state.showElements,
      }
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
      }
    default:
      return state
  }
}
