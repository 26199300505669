import React, { LegacyRef } from 'react'
import './NavigationButton.scss'

interface NavigationButtonProps {
  reference?: LegacyRef<HTMLButtonElement>
  onFocusLeft?: string
  onFocusRight?: string
  onFocusDown?: string
  onFocusUp?: string
  tabIndex?: number
  onClick: () => void
  children?: React.ReactNode
}

const NavigationButton = ({
  reference,
  onFocusLeft,
  onFocusUp,
  onFocusDown,
  onFocusRight,
  tabIndex,
  onClick,
  children,
}: NavigationButtonProps) => {
  return (
    <button
      className="navigation-button focusable"
      data-sn-left={onFocusLeft}
      data-sn-right={onFocusRight}
      data-sn-up={onFocusUp}
      data-sn-down={onFocusDown}
      tabIndex={tabIndex}
      ref={reference}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default NavigationButton
