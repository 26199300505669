import { onEnterKeyPressAndClick } from '@utils/helpers'
import './SecondaryPlayerButton.scss'
import React, { LegacyRef } from 'react'
import classNames from 'classnames'

interface SecondaryPlayerButtonProps {
  onClick: () => void
  className?: string
  buttonRef?: LegacyRef<HTMLButtonElement>
  children?: React.ReactNode
  id?: string
  preventNavigatingRight?: boolean
}

const SecondaryPlayerButton = ({
  children,
  onClick,
  buttonRef,
  className,
  id,
  preventNavigatingRight,
}: SecondaryPlayerButtonProps) => {
  return (
    <button
      className={classNames('secondary-player-button', 'focusable', className)}
      onClick={(e) => onEnterKeyPressAndClick(e, onClick)}
      ref={buttonRef}
      id={id}
      data-sn-right={preventNavigatingRight ? '' : undefined}
    >
      {children}
    </button>
  )
}

export default SecondaryPlayerButton
