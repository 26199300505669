import { TextTag } from '@enums/textTag'
import React from 'react'

interface TextProps {
  tag: TextTag
  className?: string
  children: React.ReactNode
}

const Text = ({ tag, className, children }: TextProps) => {
  return React.createElement(tag, { className: className }, children)
}

export default Text
