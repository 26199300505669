import Text from '@components/Text/Text'
import { useTypedSelector } from '../../store'
import { TextTag } from '@enums/textTag'

interface SearchNoResultProps {
  value: string
}

const SearchNoResultLabels = ({ value }: SearchNoResultProps) => {
  const V = useTypedSelector((state) => state.vocabulary.data)

  return (
    <div className="search-text-no-results">
      <Text tag={TextTag.P}>{V.searchNoResultsTitle}</Text>
      <div className="second-line">
        <Text tag={TextTag.P}>
          {V.searchPageNoMatchLabel} {value}
        </Text>
      </div>
    </div>
  )
}

export default SearchNoResultLabels
