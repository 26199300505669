import { AdConfig } from 'bitmovin-player'
import { AdsConfig, DrmConfig, StreamUrl } from '@interfaces/player.interface'
import { fetchToApi } from '@services/api'
import { fetchMethods } from '@constants/api.const'

export const getVideoInfo = async (slug: string, apiToken: string) => {
  try {
    return await fetchToApi(`video/${slug}/entitlement`, fetchMethods.GET, undefined, undefined, apiToken)
  } catch (error) {
    return error
  }
}

export const reduceAdsConfig = (adsConfig: AdsConfig[]) =>
  adsConfig?.map(({ id, position, title, type, url }) => ({
    id,
    position,
    title,
    tag: { type, url },
  })) as AdConfig[]

export const reduceUrlByType = (elements: Array<StreamUrl | DrmConfig>, withLA?: boolean) =>
  elements?.reduce(
    (obj, { type, url }) =>
      Object.assign(obj, {
        [type.toLowerCase()]: withLA ? { LA_URL: url } : url,
      }),
    {},
  )

export const registerMediaControlKeys = () => {
  if (navigator.userAgent.match(/Tizen/i)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line no-undef
    typeof tizen !== 'undefined' && tizen.tvinputdevice.registerKeyBatch(['MediaPlay', 'MediaPause', 'MediaPlayPause'])
  }
}
