import React, { useCallback } from 'react'
import { CardProps, FallbackImages, HorizontalCardProps, RailDetailsItem } from '@interfaces/index'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  renderCountdownSportsCard,
  renderLiveSportsCard,
  renderScheduleSportsCard,
  renderSimpleSportsCard,
} from '@components/Cards/Sports/Sports'
import {
  renderHorizontalSimpleCard,
  renderHorizontalCardWithTitle,
  renderHorizontalCategoryCard,
  renderHorizontalContinueWatchingCard,
  renderHorizontalComplicatedCard,
  renderVerticalSimpleCard,
  renderHorizontalMyListCardWithTitle,
  renderHorizontalEpisodeCardWithTitle,
  renderSimpleMoviePoster,
} from '@components/Cards/Movies/CardTypes'
import { CardTypes } from '@constants/index'
import './Card.scss'
import classNames from 'classnames'
import { DocumentData } from 'firebase/firestore'
import { useTypedSelector } from 'store'
import { routes } from '@constants/routes.const'

const Card = ({
  item,
  rowType,
  isActive,
  isFocusable,
  info,
  onFocusUpId,
  index,
  disableMoveDown,
  disableMoveUp,
  lastFocusedCard,
  onFocusLeft,
}: HorizontalCardProps) => {
  const navigate = useNavigate()
  const config = useTypedSelector((state) => state.config.data)
  const moveDown = isFocusable && disableMoveDown
  const moveUp = isFocusable && disableMoveUp
  const moveLeft = isFocusable && onFocusLeft
  const fallbackImages = config?.features.fallbackImages
  const location = useLocation()

  const renderCard = (
    item: CardProps | RailDetailsItem | DocumentData,
    rowType: string | undefined,
    isActive?: boolean,
    info?: string | undefined,
    onFocusUpId?: string | undefined | null,
    index?: number | undefined,
    fallbackImages?: FallbackImages,
  ) => {
    switch (rowType) {
      case CardTypes.VERTICAL_SIMPLE:
        return renderVerticalSimpleCard(item, isActive, fallbackImages)
      case CardTypes.HORIZONTAL_CATEGORY:
        return renderHorizontalCategoryCard(item, isActive)
      case CardTypes.HORIZONTAL_SIMPLE:
        return renderHorizontalSimpleCard(item, isActive, fallbackImages)
      case CardTypes.HORIZONTAL_CONTINUE_WATCHING:
        return renderHorizontalContinueWatchingCard(item, isActive, fallbackImages)
      case CardTypes.HORIZONTAL_COMPLICATED:
      case CardTypes.HORIZONTAL_COMPLICATED_DATE:
        return renderHorizontalComplicatedCard(item, rowType, isActive, fallbackImages)
      case CardTypes.MOVIE_POSTER_SIMPLE:
        return renderSimpleMoviePoster(item, isActive, fallbackImages)
      case CardTypes.SPORTS_SIMPLE:
        return renderSimpleSportsCard(item, isActive)
      case CardTypes.SPORTS_SCHEDULE:
        return renderScheduleSportsCard(item, isActive)
      case CardTypes.SPORTS_LIVE:
        return renderLiveSportsCard(item, isActive)
      case CardTypes.SPORTS_COUNTDOWN:
        return renderCountdownSportsCard(item, isActive)
      case CardTypes.SEARCH:
        return renderHorizontalCardWithTitle(item, fallbackImages)
      case CardTypes.MYLIST:
        return renderHorizontalMyListCardWithTitle(item, fallbackImages)
      case CardTypes.EPISODES:
        return renderHorizontalEpisodeCardWithTitle(item, info, onFocusUpId, index, isActive, fallbackImages)
      default:
        return renderHorizontalSimpleCard(item, isActive, fallbackImages)
    }
  }

  const handleNavigation = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      e.preventDefault()
      onEnterKeyPressAndClick(e, () => navigate(item.callToActions.webUrl))
    },
    [item?.callToActions?.webUrl, navigate],
  )

  const cardId = useCallback(
    (id: string) => (location.pathname.includes(routes.SEARCH) ? `search-${id}` : id),
    [location.pathname],
  )

  return (
    <div
      className={classNames('card-container scroll-center', isFocusable && 'focusable')}
      id={cardId(item.id)}
      tabIndex={isFocusable ? -1 : 0}
      onKeyDown={handleNavigation}
      onClick={handleNavigation}
      data-sn-down={moveDown}
      data-sn-up={onFocusUpId ?? moveUp}
      data-sn-left={moveLeft}
      onFocus={lastFocusedCard}
    >
      {renderCard(item, rowType, isActive, info, onFocusUpId, index, fallbackImages)}
    </div>
  )
}

export default Card
