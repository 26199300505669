import './SearchInputField.scss'
import React, { LegacyRef } from 'react'
import { SearchIcon } from '@assets/index'
import Input from '@components/Input/Input'

interface SearchInputFieldProps {
  inputRef?: LegacyRef<HTMLInputElement>
  value?: string
  lastFocusedCard?: string
  wrapperRef?: LegacyRef<HTMLDivElement>
  onFocusInput: () => void
  onBlurInput: () => void
  onValueInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDownInput?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const SearchInputField = ({
  wrapperRef,
  inputRef,
  value,
  onValueInput,
  onKeyDownInput,
  onFocusInput,
  onBlurInput,
}: SearchInputFieldProps) => {
  return (
    <div className="search-box focusable" tabIndex={-1} ref={wrapperRef} onFocus={onFocusInput} onBlur={onBlurInput}>
      <SearchIcon className="search-box-icon" />
      <Input
        type="text"
        className="search-box-input"
        inputRef={inputRef}
        value={value}
        focusOnUp=""
        onValueInput={onValueInput}
        onKeyDown={onKeyDownInput}
      />
    </div>
  )
}

export default SearchInputField
