import { ProfileTitle } from '@components/Profile/ProfileTitle/ProfileTitle'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTypedSelector } from 'store'
import './AppLanguage.scss'
import LanguageButton from '../LanguageButton/LanguageButton'
import { onBackKeyPress } from '@utils/helpers'
import { useNavigate } from 'react-router-dom'
import { language } from '@constants/lang.const'
import { Language } from '@constants/index'
import { Vocabulary } from '@interfaces/index'

interface AppLanguageProps {
  showMenu: (status: boolean) => void
}

const AppLanguage = ({ showMenu }: AppLanguageProps) => {
  const V = useTypedSelector((state) => state.vocabulary.data)
  const enRef = useRef<HTMLDivElement>(null)
  const esRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const getVocabFromLS = JSON.parse(localStorage.getItem(userObject?.uid) as string)
  const config = useTypedSelector((state) => state.config.data)
  const vocabularyList = config?.vocabulary

  const closeAppLanguagePage = useCallback(
    (e: KeyboardEvent) =>
      onBackKeyPress(e, () => {
        navigate(-1)
      }),
    [navigate],
  )

  const focusLanguage = useCallback(() => {
    if (getVocabFromLS === language.EN) {
      enRef?.current?.focus()
    } else if (getVocabFromLS === language.ES) {
      esRef?.current?.focus()
    }
  }, [getVocabFromLS])

  useEffect(() => {
    showMenu(false)

    focusLanguage()
  }, [focusLanguage, getVocabFromLS, showMenu])

  useEffect(() => {
    document.addEventListener('keydown', closeAppLanguagePage, false)

    return () => document.removeEventListener('keydown', closeAppLanguagePage, false)
  })

  return (
    <div className="app-lang-info-container">
      <ProfileTitle title={V.profileInfoAppLanguageTitle} />
      <div className="app-buttons-container">
        {vocabularyList?.map((vocabulary: Vocabulary) => {
          const { vocabKey, language } = vocabulary
          const ref = language === Language.EN ? enRef : esRef

          return <LanguageButton key={vocabKey} language={language} buttonText={V[vocabKey]} buttonRef={ref} />
        })}
      </div>
    </div>
  )
}

export default AppLanguage
