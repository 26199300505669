import React from 'react'
import '@pages/Skeleton/DetailsPageSkeleton/DetailsSkeleton.scss'
import HeroSkeleton from '@components/Skeleton/HeroSkeleton/HeroSkeleton'
import TabsSkeleton from '@components/Skeleton/TabsSkeleton/TabsSkeleton'
import SynopsisPrimarySkeleton from '@components/Skeleton/SynopsisPrimarySkeleton/SynopsisPrimarySkeleton'

const MoviesDetailsSkeleton = () => {
  return (
    <div className="details-skeleton-wrapper">
      <HeroSkeleton page="details" />
      <TabsSkeleton />
      <SynopsisPrimarySkeleton />
    </div>
  )
}

export default MoviesDetailsSkeleton
