import { MutableRefObject, useRef, useEffect } from 'react'

type Callback = (e: MouseEvent) => void

export default function useOutsideClick<T extends HTMLElement>(callback: Callback): MutableRefObject<T | null> {
  const innerRef = useRef<T>(null)
  const callbackRef = useRef<Callback>(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (innerRef.current && !innerRef.current.contains(e.target as Node)) {
        callbackRef.current(e)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [innerRef, callback])

  return innerRef
}
