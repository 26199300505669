import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import React from 'react'
import { useTypedSelector } from 'store'

interface CookiesPolicyProps {
  scrollRef: React.LegacyRef<HTMLDivElement>
  onMouseEnter: () => void
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined
  focusOnLeft: string
}

const CookiesPolicy = ({ scrollRef, onKeyDown, focusOnLeft, onMouseEnter }: CookiesPolicyProps) => {
  const V = useTypedSelector((state) => state.vocabulary.data)

  return (
    <div
      id="legal-text"
      className="legal-content-text-item focusable"
      data-sn-left={focusOnLeft}
      tabIndex={-1}
      ref={scrollRef}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
    >
      <div className="legal-content-text-template">
        <div className="legal-content-text-template-title">{V.legalSectionCookiesPolicyLabel}</div>
        <div className="legal-content-text-template-description">
          <Text tag={TextTag.P} className="policy-introduction">
            {V.cookiesPolicyText_1}
          </Text>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.cookiesPolicyText_2}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_3}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_4}
              </Text>
            </ul>
          </>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.cookiesPolicyText_9}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_10}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_11}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_12}
              </Text>
            </ul>
          </>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.cookiesPolicyText_5}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_6}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_7}
              </Text>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_8}
              </Text>
            </ul>
          </>
          <>
            <Text tag={TextTag.P} className="policy-title">
              {V.cookiesPolicyText_13}
            </Text>
            <ul>
              <Text tag={TextTag.LI} className="policy-title">
                {V.cookiesPolicyText_14}
              </Text>
            </ul>
          </>
        </div>
      </div>
    </div>
  )
}

export default CookiesPolicy
