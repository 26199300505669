import { SET_AUDIO, SET_AUDIO_LIST, SET_SUBTITLE, SET_SUBTITLE_LIST, SET_IS_PLAYING } from '@actions/playerActions'
import { Audio, Subtitle } from '@interfaces/player.interface'

const initialState = {
  subtitle: '',
  subtitleList: [
    {
      enabled: false,
      forced: false,
      id: '',
      kind: 'captions',
      label: '',
      lang: '',
      url: '',
    },
  ],
  audio: '',
  audioList: [{ id: '', label: '', lang: '' }],
  isPlaying: true,
}

interface PlayerAction {
  type: string
  payload: {
    subtitle: string
    subtitleList: Subtitle[]
    audio: string
    audioList: Audio[]
    isPlaying: boolean
  }
}

export default function playerReducer(state = initialState, action: PlayerAction) {
  switch (action.type) {
    case SET_SUBTITLE:
      return {
        ...state,
        subtitle: action.payload.subtitle,
      }

    case SET_AUDIO:
      return {
        ...state,
        audio: action.payload.audio,
      }

    case SET_SUBTITLE_LIST:
      return {
        ...state,
        subtitleList: action.payload.subtitleList,
      }

    case SET_AUDIO_LIST:
      return {
        ...state,
        audioList: action.payload.audioList,
      }

    case SET_IS_PLAYING:
      return {
        ...state,
        isPlaying: action.payload.isPlaying,
      }

    default:
      return state
  }
}
