export interface QueryParams {
  [key: string]: string
}

export const generatePageStructureByQueryParams = (endpointStructure: string, queryParams: QueryParams) => {
  const queryString = Object.keys(queryParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&')

  endpointStructure += `?${queryString}`

  return endpointStructure
}
