import axios from 'axios'
import { Dispatch } from 'redux'
import { Section } from '@interfaces/index'
import { Action } from '@reducers/sectionsReducer'

export const FETCH_SECTIONS_REQUEST = 'FETCH_SECTIONS_REQUEST' as const
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS' as const
export const FETCH_SECTIONS_FAILURE = 'FETCH_SECTIONS_FAILURE' as const

export const fetchSectionsRequest = () => ({ type: FETCH_SECTIONS_REQUEST })
export const fetchSectionsSuccess = (sections: Section[]) => ({
  type: FETCH_SECTIONS_SUCCESS,
  payload: sections,
})
export const fetchSectionsFailure = (error: string) => ({
  type: FETCH_SECTIONS_FAILURE,
  payload: error,
})

export const fetchSections = (routeData: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(fetchSectionsRequest())
    axios
      .get(process.env.REACT_APP_BASE_URL + routeData)
      .then((res) => {
        const sections = res.data.sections
        dispatch(fetchSectionsSuccess(sections))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(fetchSectionsFailure(errorMsg))
      })
  }
}
