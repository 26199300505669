import React, { LegacyRef, ReactNode, useCallback } from 'react'
import './OverlayWrapper.scss'
import Title from '@components/Title/Title'
import { VideoType } from '@interfaces/player.interface'
import classNames from 'classnames'
import SecondaryPlayerButton from '../SecondaryPlayerButton/SecondaryPlayerButton'
import { AudioSubtitles, BackArrow, ReplayIcon } from '@assets/index'
import PrimaryPlayerButton from '../PrimaryPlayerButton/PrimaryPlayerButton'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from 'store'
import PlayerModal from '@components/PlayerModal/PlayerModal'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import { playerButtons } from '@constants/player.const'

interface ContentPositionProps {
  className?: string
  children?: ReactNode
}

interface OverlayWrapperProps {
  videoConfig?: VideoType
  playFromBeginning: () => void
  playFromBeginningButtonRef: LegacyRef<HTMLButtonElement>
  showModal: boolean
  setShowModal: (status: boolean | ((prev: boolean) => boolean)) => void | undefined
  play: () => void
  pause: () => void
  isLive: boolean
}

export const OverlayWrapper = ({
  videoConfig,
  playFromBeginning,
  playFromBeginningButtonRef,
  showModal,
  setShowModal,
  play,
  pause,
  isLive,
}: OverlayWrapperProps) => {
  const navigate = useNavigate()
  const V = useTypedSelector((state) => state.vocabulary.data)

  const handleShowModal = useCallback(() => setShowModal((prev) => !prev), [setShowModal])

  const handleGoBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <>
      <div className="overlay-wrapper">
        <div className="overlay-wrapper-top-section">
          <ContentPosition className="top-left">
            <PrimaryPlayerButton onClick={handleGoBack} className="back-button">
              <BackArrow />
            </PrimaryPlayerButton>
          </ContentPosition>
        </div>
        <div className="overlay-wrapper-center" /> {/* Inside we can put ContentPosition components when we need them*/}
        <div className="overlay-wrapper-bottom-section">
          <ContentPosition className="bottom-left">
            <div className="movie-info">
              <Title title={videoConfig?.title} />
              <Text tag={TextTag.P} className="description">
                {videoConfig?.description}
              </Text>
            </div>
            <div className="buttons">
              {!isLive && (
                <SecondaryPlayerButton onClick={playFromBeginning} buttonRef={playFromBeginningButtonRef}>
                  <ReplayIcon />
                  {V.playerPlayFromBeginning}
                </SecondaryPlayerButton>
              )}
              <SecondaryPlayerButton
                onClick={handleShowModal}
                id={playerButtons.AUDIO_AND_SUBTITLES_BUTTON}
                // we should add preventNavigatingRight to the last button in the buttons overlay
                // so if we add more buttons this prop should be deleted from this button and added to the last one.
                preventNavigatingRight
              >
                <AudioSubtitles />
                {V.playerAudioAndSubtitles}
              </SecondaryPlayerButton>
            </div>
          </ContentPosition>
        </div>
      </div>
      {showModal && (
        <PlayerModal
          continuePlaying //true-video will continue to play while modal is opened, false-video will pause
          showModal={showModal}
          setShowModal={setShowModal}
          play={play}
          pause={pause}
        />
      )}
    </>
  )
}

export const ContentPosition = ({ children, className }: ContentPositionProps) => {
  return <div className={classNames(className)}>{children}</div>
}
