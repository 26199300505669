import React from 'react'
import './Title.scss'
import { titleTypes } from '@constants/element-types.const'
import classNames from 'classnames'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

interface TitleProps {
  type?: typeof titleTypes.PAGE | typeof titleTypes.SECTION
  className?: string
  title?: string
}

const Title = ({ title, className, type }: TitleProps) => {
  return (
    <Text tag={TextTag.P} className={classNames('title', className, type)}>
      {title}
    </Text>
  )
}

export default Title
