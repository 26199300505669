import React from 'react'
import classNames from 'classnames'
import '../Card.scss'
import { CardProps, FallbackImages, Genre, RailDetailsItem } from '@interfaces/index'
import { convertDate, formatSecondsToHoursAndMinutes } from '@utils/helpers'
import Image from '@components/Image/Image'
import { CardTypes } from '@constants/index'
import { categoryType } from '@constants/category.const'
import { DocumentData } from 'firebase/firestore'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'

export const renderSimpleMoviePoster = (
  item: CardProps | RailDetailsItem | DocumentData,
  isActive?: boolean,
  fallbackImages?: FallbackImages,
) => {
  const {
    id,
    title,
    contextData: { imageBackdrop, imageCard },
  } = item

  return (
    <div
      className={classNames('poster-card', {
        'poster-card__focus': isActive,
      })}
      id={id}
    >
      <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
        <Image type="poster" image={(imageCard || imageBackdrop) ?? fallbackImages?.horizontalCard} alt={title} />
      </div>
    </div>
  )
}

export const renderHorizontalSimpleCard = (
  item: CardProps | RailDetailsItem | DocumentData,
  isActive?: boolean,
  fallbackImages?: FallbackImages,
) => {
  const {
    id,
    title,
    contextData: { imageBackdrop, imageCard },
  } = item

  return (
    <div
      className={classNames('row-1-card', {
        'row-1-card__full_opacity': isActive,
      })}
      id={`more-like-this-${id}`}
    >
      <div
        className={classNames('row-1-card__focus', {
          'row-1-card__focused': isActive,
        })}
      />
      <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
        <Image image={(imageCard || imageBackdrop) ?? fallbackImages?.horizontalCard} alt={title} />
      </div>
    </div>
  )
}

export const renderVerticalSimpleCard = (
  item: CardProps | RailDetailsItem | DocumentData,
  isActive?: boolean,
  fallbackImages?: FallbackImages,
) => {
  const {
    title,
    contextData: { imagePoster },
  } = item

  return (
    <div
      className={classNames('row-2-card', {
        'row-2-card__full_opacity': isActive,
      })}
    >
      <div
        className={classNames('row-2-card__focus', {
          'row-2-card__focused': isActive,
        })}
      ></div>
      <div
        className={classNames(
          'card-image-wrapper vertical',
          { 'row-card-image-focused': isActive },
          { 'fallback-image': !imagePoster },
        )}
      >
        <Image image={imagePoster ?? fallbackImages?.verticalCard} alt={title} />
      </div>
    </div>
  )
}

export const renderHorizontalCategoryCard = (item: CardProps | RailDetailsItem | DocumentData, isActive?: boolean) => {
  const { title } = item

  return (
    <div
      className={classNames('row-category-card', {
        'row-category-card__is_active': isActive,
      })}
    >
      <Text tag={TextTag.P}>{title}</Text>
    </div>
  )
}

export const renderHorizontalComplicatedCard = (
  item: CardProps | RailDetailsItem | DocumentData,
  rowType: string | undefined,
  isActive?: boolean,
  fallbackImages?: FallbackImages,
) => {
  const {
    title,
    contextData: { imageBackdrop, imageCard, releaseDate, genres, duration },
  } = item

  return (
    <div
      className={classNames('row-3-card', {
        'row-3-card__is_active': isActive,
        'row-3-card__full_opacity': isActive,
      })}
    >
      <div className="img-placeholder"></div>
      <div className="row-3-card__top-container">
        <div
          className={classNames('row-3-card__focus', {
            'row-3-card__focused': isActive,
          })}
        ></div>
        <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
          <Image image={(imageCard || imageBackdrop) ?? fallbackImages?.horizontalCard} alt={title} />
        </div>
        <div className={classNames(`tags-container`, { 'tags-container__is_active': isActive })}>
          {genres?.map((genre: Genre, index: number) =>
            index <= 1 ? (
              <Text tag={TextTag.P} key={genre.name} className="single-tag">
                {genre.name}
              </Text>
            ) : null,
          )}
        </div>
        {duration && (
          <div className={classNames('duration-container', { 'duration-container__is_active': isActive })}>
            {formatSecondsToHoursAndMinutes(duration)}
          </div>
        )}
      </div>
      <div
        className={`row-3-card__bottom-container 
        ${rowType === CardTypes.HORIZONTAL_COMPLICATED_DATE && 'row-3-card__bottom-container-one-line'}
        ${rowType === CardTypes.HORIZONTAL_COMPLICATED && 'row-3-card__bottom-container-two-lines'}`}
      >
        <Text tag={TextTag.P}>{title}</Text>
        <Text tag={TextTag.P}>{releaseDate && convertDate(releaseDate)}</Text>
      </div>
    </div>
  )
}

export const renderHorizontalContinueWatchingCard = (
  item: CardProps | RailDetailsItem | DocumentData,
  isActive?: boolean,
  fallbackImages?: FallbackImages,
) => {
  const {
    title,
    subtitle,
    contextData: { imageBackdrop, imageCard },
    callToActions,
  } = item

  const className = 'row-4-card'
  const category = callToActions?.webUrl?.split('/')[1]

  const renderProgress = (isActive?: boolean, duration?: string) => {
    const total = duration ? parseInt(duration.split(':')[0]) : 0
    const watched = total * 0.6
    const width = total > 0 ? ((total - watched) / total) * 100 : 0
    return (
      <div className={`${className}__progress ${isActive && `${className}__progress--focus`}`}>
        <div className={`${className}__progress--active`} style={{ width: `${width}%` }}></div>
      </div>
    )
  }
  const progressTimeTrack = '12:25'

  return (
    <div className="row-4-card">
      <div
        className={classNames('row-4-card__focus', {
          'row-4-card__focused': isActive,
        })}
      >
        <div className={`${className}__focus--wrapper`}>
          <Text tag={TextTag.P} className={`${className}__focus--wrapper--title`}>
            {title}
          </Text>
          <Text tag={TextTag.P} className={`${className}__focus--wrapper--subtitle`}>
            {category === categoryType.SERIE && subtitle}
          </Text>
        </div>
        {renderProgress(isActive, progressTimeTrack)}
      </div>
      <div
        className={classNames(`${className}__container`, {
          'row-4-card__focused': isActive,
        })}
      >
        <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
          <Image image={(imageCard || imageBackdrop) ?? fallbackImages?.horizontalCard} alt={title} />
        </div>
        {!isActive && renderProgress(isActive, progressTimeTrack)}
      </div>
    </div>
  )
}

export const renderHorizontalCardWithTitle = (
  item: CardProps | RailDetailsItem | DocumentData,
  fallbackImages?: FallbackImages,
) => {
  const {
    id,
    title,
    contextData: { imageBackdrop, imageCard },
  } = item

  return (
    <div className="row-5-card" id={id}>
      <div className="card-image-wrapper">
        <Image image={(imageCard || imageBackdrop) ?? fallbackImages?.horizontalCard} alt={title} />
      </div>
      <Text tag={TextTag.P}>{title}</Text>
    </div>
  )
}

export const renderHorizontalMyListCardWithTitle = (
  item: CardProps | RailDetailsItem | DocumentData,
  fallbackImages?: FallbackImages,
) => {
  const { title, image, contextData } = item

  return (
    <div className="row-5-card" id={item.id}>
      <div className="card-top">
        <div className="card-image-wrapper">
          <Image
            image={(image || contextData?.imageCard || contextData?.imageBackdrop) ?? fallbackImages?.horizontalCard}
            alt={title}
          />
        </div>
      </div>
      <div className="card-bottom">
        <Text tag={TextTag.P}>{title}</Text>
      </div>
    </div>
  )
}

export const renderHorizontalEpisodeCardWithTitle = (
  item: CardProps | RailDetailsItem | DocumentData,
  info: string | undefined,
  onFocusUpId?: string | number | null,
  index?: number,
  isActive?: boolean,
  fallbackImages?: FallbackImages,
) => {
  const {
    id,
    title,
    contextData: { Thumbnail },
    description,
  } = item

  return (
    <div className={`row-6-card ${isActive && 'active'}`} id={id} data-sn-up={onFocusUpId}>
      <div className="episode-card-top">
        <Image image={Thumbnail ?? fallbackImages?.horizontalCard} alt={title} />
        <Text tag={TextTag.P} className="episode-season">
          {info}
        </Text>
        <div className="episode-video-bar">
          <div className="episode-video-bar-progress"></div>
        </div>
      </div>
      <div className="episode-card-bottom">
        <Text tag={TextTag.P} className="episode-name">
          {index !== undefined ? `${index + 1}. ${title}` : `${title}`}
        </Text>
        <Text tag={TextTag.P} className="episode-description">
          {description}
        </Text>
        {/* Currently we don't receive data from BE for the episodes length, this will remain commented until next changes */}
        {/* <Text tag={TextTag.P} className="episode-length"></Text> */}
      </div>
    </div>
  )
}
