import React from 'react'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { routes } from '@constants/routes.const'

const ProtectedHomeRoutes = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkUser = () => {
    const userToken = localStorage.getItem('userToken')
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false)
      return navigate(routes.INITIAL_PAGE)
    }
    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkUser()
    return () => checkUser()
  })

  return <>{isLoggedIn ? <Outlet /> : null}</>
}

export default ProtectedHomeRoutes
