import React, { LegacyRef } from 'react'
import './PrimaryPlayerButton.scss'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import classNames from 'classnames'

interface PrimaryPlayerButtonProps {
  onClick: () => void
  className?: string
  buttonRef?: LegacyRef<HTMLButtonElement>
  children?: React.ReactNode
  id?: string
  disabledMovement?: boolean
}
const PrimaryPlayerButton = ({
  onClick,
  className,
  buttonRef,
  children,
  id,
  disabledMovement,
}: PrimaryPlayerButtonProps) => {
  return (
    <button
      className={classNames('primary-player-button', 'focusable', className)}
      id={id}
      onClick={(e) => onEnterKeyPressAndClick(e, onClick)}
      ref={buttonRef}
      data-sn-up={disabledMovement ? '' : undefined}
      data-sn-down={disabledMovement ? '' : undefined}
      data-sn-left={disabledMovement ? '' : undefined}
      data-sn-right={disabledMovement ? '' : undefined}
    >
      {children}
    </button>
  )
}

export default PrimaryPlayerButton
