import {
  FETCH_MOVIE_FAILURE,
  FETCH_MOVIE_SUCCESS,
  FETCH_MOVIE_REQUEST,
  RESET_MOVIE_DETAILS,
} from '@actions/movieDetailActions'
import {
  FetchMovieFailure,
  FetchMovieInit,
  FetchMovieRequest,
  FetchMovieSuccess,
} from '@actions-types/movieDetailActionsTypes'
import { Movie } from '@interfaces/index'

interface State {
  loadingMovie: number
  movie: Movie
  error: string
}

const movieDataObject = {
  id: '',
  slug: '',
  title: '',
  subtitle: '',
  description: '',
  callToActions: {
    navType: '',
    dataUrl: '',
    webUrl: '',
    templateUrl: '',
  },
  actors: [],
  soundtracks: [],
  directors: [],
  videos: [],
  customContent: '',
  contextData: {
    duration: 0,
    tags: [],
    ageRating: '',
    longDescription: '',
    color: '',
    contentCategory: '',
    genres: [],
    heroImage: '',
    imageBackdrop: '',
    imagePoster: '',
    imageTitle: '',
    imageCard: '',
    mobileImage: '',
    percentageMatchScore: 0,
    quality: '',
    releaseDate: '2023-05-24',
    director: '',
  },
  relateds: [],
  products: [],
  seasons: [
    {
      id: '',
      slug: '',
      title: '',
      description: '',
      callToActions: {
        navType: '',
        dataUrl: '',
        webUrl: '',
        templateUrl: '',
      },
      name: '',
      episodes: [],
    },
  ],
}

const initialState: State = {
  loadingMovie: 0,
  movie: movieDataObject,

  error: '',
}

const actionInit = {
  type: '',
  payload: {},
}

export type Action = FetchMovieRequest | FetchMovieSuccess | FetchMovieFailure | FetchMovieInit

export const movieDetailReducer = (state: State = initialState, action: Action = actionInit): State => {
  switch (action.type) {
    case FETCH_MOVIE_REQUEST:
      return {
        ...state,
        loadingMovie: state.loadingMovie + 1,
        movie: initialState.movie,
      }
    case FETCH_MOVIE_SUCCESS:
      return {
        ...state,
        loadingMovie: state.loadingMovie - 1,
        movie: (action as FetchMovieSuccess).payload,
        error: '',
      }
    case FETCH_MOVIE_FAILURE:
      return { ...state, loadingMovie: 0, error: (action as FetchMovieFailure).payload }
    case RESET_MOVIE_DETAILS:
      return {
        ...state,
        loadingMovie: state.loadingMovie,
        movie: movieDataObject,
        error: '',
      }
    default:
      return state
  }
}
