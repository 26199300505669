import { SKELETON_CARD_POSITIONS, SKELETON_CARD_TYPES } from '@constants/skeleton.const'
import './CardSkeleton.scss'

interface CardSkeletonProps {
  type: (typeof SKELETON_CARD_TYPES)[number]
  position: (typeof SKELETON_CARD_POSITIONS)[number]
  title?: boolean
  description?: boolean
}

const CardSkeleton = ({ type, position, title, description }: CardSkeletonProps) => {
  return (
    <div className="card-skeleton-wrapper">
      <div className={`skeleton-element card-skeleton-image ${type} ${position}`}></div>
      {title && <div className="skeleton-element card-skeleton-title"></div>}
      {description && <div className="skeleton-element card-skeleton-description"></div>}
    </div>
  )
}

export default CardSkeleton
