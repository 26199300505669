import { tagTypes } from '@constants/element-types.const'
import './Tag.scss'

interface TagProps {
  type: typeof tagTypes.CARD | typeof tagTypes.STEP
  label: string
}

const Tag = ({ label, type }: TagProps) => {
  return <div className={`tag ${type && type}`}>{label}</div>
}

export default Tag
