import React, { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { checkUser } from '@utils/auth'
import { useTypedSelector } from 'store'

const ProtectedAuthRoutes = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const initialRoute = useTypedSelector((state) => state.menu.initialRoute)

  const handleLoginSuccess = useCallback(() => {
    setIsLoggedIn(true)
    initialRoute && navigate(initialRoute)
  }, [navigate, initialRoute])

  const handleLoginError = () => setIsLoggedIn(false)

  useEffect(() => {
    initialRoute && checkUser(handleLoginSuccess, handleLoginError)
  }, [initialRoute, handleLoginSuccess])

  return <>{!isLoggedIn ? <Outlet /> : null}</>
}

export default ProtectedAuthRoutes
