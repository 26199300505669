import React, { useEffect, useState } from 'react'
import { DocumentData } from 'firebase/firestore'
import Card from '@components/Cards/Card'
import { RowTypes } from 'components'
import './MyList.scss'
import SpatialNavigation from '@utils/spatial-navigation.js'
import { getAllMyListItems } from '@services/myList'
import EmptyListContainer from '@components/EmptyListContainer/EmptyListContainer'
import Skeleton from '@pages/Skeleton/Skeleton'
import { useTypedSelector } from 'store'
import { addLastFocusedItemToSS } from '@utils/helpers'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
interface MyListProps {
  showMenu: (status: boolean) => void
}

const MyList = ({ showMenu }: MyListProps) => {
  const userObject = JSON.parse(localStorage.getItem('user') as string)
  const userId = userObject?.firebaseUid
  const [myListCards, setMyListCards] = useState<DocumentData[]>()
  const [isloading, setIsLoading] = useState(true)
  const SN = SpatialNavigation
  const V = useTypedSelector((state) => state.vocabulary.data)
  const lastFocusedCard = JSON.parse(sessionStorage.getItem('lastFocusedCard-myList') as string)
  const findLastFocusedCard = myListCards?.find((card) => card.id === lastFocusedCard)

  useEffect(() => {
    showMenu(true)
  }, [showMenu])

  useEffect(() => {
    if (userId) {
      setIsLoading(true)
      getAllMyListItems(userId)
        .then((res) => {
          setMyListCards(res)
          setIsLoading(false)
        })
        .catch((error) => {
          console.log('Error getting items from My List:', error)
          setIsLoading(false)
        })
    }
  }, [userId])

  useEffect(() => {
    if (myListCards) {
      lastFocusedCard && findLastFocusedCard ? SN.focus(`#${lastFocusedCard}`) : SN.focus('my-list')
    }
  }, [myListCards, SN, lastFocusedCard, findLastFocusedCard])

  return (
    <div className="my-list">
      {!isloading && (
        <Text tag={TextTag.P} className="my-list-title">
          {V.menuItemMyList}
        </Text>
      )}
      {isloading ? (
        <Skeleton />
      ) : (
        <>
          {myListCards && myListCards.length > 0 ? (
            <div className="card-wrapper">
              {myListCards.map((element: DocumentData, index: number) => {
                return (
                  <Card
                    key={index}
                    item={element}
                    rowType={RowTypes.MYLIST}
                    index={index}
                    isFocusable={true}
                    lastFocusedCard={() => addLastFocusedItemToSS(element.id, RowTypes.MYLIST)}
                  />
                )
              })}
            </div>
          ) : (
            <EmptyListContainer
              firstSubtitle={V.myListPageEmptyTitle}
              secondSubtitle={V.myListPageEmptyDescription}
              buttonTitle={V.myListPageEmptyButtonText}
            />
          )}
        </>
      )}
    </div>
  )
}

export default MyList
