import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import './PlayerControls.scss'
import { OverlayWrapper } from './OverlayWrapper/OverlayWrapper'
import { PauseIcon, PlayIcon } from '@assets/index'
import PrimaryPlayerButton from './PrimaryPlayerButton/PrimaryPlayerButton'
import { registerMediaControlKeys } from '@utils/player.util'
import { KeyName, KeyCode } from '@constants/index'
import { VideoType } from '@interfaces/player.interface'
import { useTypedSelector } from 'store'
import classNames from 'classnames'
import { PlayerProgressBar } from './PlayerProgressBar/PlayerProgressBar'
import { playerButtons } from '@constants/player.const'
import { setIsPlaying } from '@actions/playerActions'
import { useDispatch } from 'react-redux'
import { onBackKeyPress } from '@utils/helpers'
import { PlayerAPI } from 'bitmovin-player'
import { useNavigate } from 'react-router-dom'

interface PlayerControlsProps {
  play: () => void
  pause: () => void
  hasPlaybackEnded: boolean
  playFromBeginning: () => void
  videoConfig: VideoType
  showModal: boolean
  setShowModal: () => void
  adIsPlaying: boolean
  currentTime: number
  duration: number
  playFromPosition: (position: number) => void
  progressRef: RefObject<HTMLProgressElement>
  player: PlayerAPI
  setPlayer: (status: null) => void
}

export const PlayerControls = ({
  play,
  pause,
  hasPlaybackEnded,
  playFromBeginning,
  videoConfig,
  showModal,
  setShowModal,
  adIsPlaying,
  currentTime,
  duration,
  playFromPosition,
  progressRef,
  player,
  setPlayer,
}: PlayerControlsProps) => {
  const [showControls, setShowControls] = useState(true)
  const [isProgressTooltipVisible, setProgressTooltipVisible] = useState(false)
  const [enableButtonMovement, setEnableButtonMovement] = useState(true)
  const showControlsDuration = 3000
  const controlsTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const playPauseButtonRef = useRef<HTMLButtonElement>(null)
  const playFromBeginningButtonRef = useRef<HTMLButtonElement>(null)
  const isPlaying = useTypedSelector((state) => state.player.isPlaying)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const hidePlayerControls = useCallback(
    (shouldHide: boolean) => {
      controlsTimerRef.current && clearTimeout(controlsTimerRef.current)
      setShowControls(true)

      if (shouldHide && !showModal && isPlaying) {
        controlsTimerRef.current = setTimeout(() => {
          setShowControls(false)
          setEnableButtonMovement(false)
        }, showControlsDuration)
      }
    },
    [isPlaying, showModal],
  )

  const playPauseVideo = useCallback(() => {
    if (!hasPlaybackEnded) {
      if (isPlaying) {
        pause()
        hidePlayerControls(false)
      } else {
        play()
        hidePlayerControls(true)
      }
    } else {
      playFromBeginning()
      hidePlayerControls(true)
    }
  }, [hasPlaybackEnded, isPlaying, pause, hidePlayerControls, playFromBeginning, play])

  /**
   * videoControls handles keyboard events for controlling video playback and visibility of player controls.
   * If playback has ended, show controls.
   * If an ad is not playing: play video on MediaPlayKey press, pause video on MediaPauseKey press, toggle play/pause on MediaPlayPauseKey press
   * If video is playing, hide controls and hide progress tooltip if visible.
   **/

  const videoControls = useCallback(
    (e: KeyboardEvent) => {
      if (hasPlaybackEnded) {
        hidePlayerControls(false)
      } else {
        if (!adIsPlaying) {
          if (e.key === KeyName.Enter && !showControls && enableButtonMovement) {
            pause()
          }
          if (e.keyCode === KeyCode.MediaPlayKey || e.key === KeyName.MediaPlay) {
            if (!isPlaying) {
              play()
              hidePlayerControls(true)
            } else {
              hidePlayerControls(true)
            }
          } else if (e.keyCode === KeyCode.MediaPauseKey || e.key === KeyName.MediaPause) {
            if (isPlaying) {
              pause()
              hidePlayerControls(false)
            }
          } else if (e.keyCode === KeyCode.MediaPlayPauseKey || e.key === KeyName.MediaPlayPause) {
            playPauseVideo()
          } else {
            if (isPlaying) {
              hidePlayerControls(true)
              isProgressTooltipVisible && setProgressTooltipVisible(false)
            }
          }
        }
      }
    },
    [
      hasPlaybackEnded,
      hidePlayerControls,
      adIsPlaying,
      showControls,
      enableButtonMovement,
      pause,
      isPlaying,
      play,
      playPauseVideo,
      isProgressTooltipVisible,
    ],
  )

  const startPlaybackFromBeginning = () => {
    playFromBeginning()
    playPauseButtonRef?.current?.focus()
  }

  const showControlsOnClick = useCallback(() => {
    isPlaying && !showControls && hidePlayerControls(true)
  }, [hidePlayerControls, isPlaying, showControls])

  const closePlayerOnBackButton = (e: KeyboardEvent) =>
    onBackKeyPress(e, () => {
      if (!showModal && document.activeElement !== progressRef.current && showControls) {
        player.destroy().then(() => setPlayer(null))
        navigate(-1)
      }
    })

  useEffect(() => {
    hidePlayerControls(true)
    registerMediaControlKeys()
  }, [hidePlayerControls])

  useEffect(() => {
    if (showControls && !hasPlaybackEnded) {
      playPauseButtonRef?.current?.focus()
    } else if (hasPlaybackEnded) {
      dispatch(setIsPlaying(false))
      setShowControls(true)
      playFromBeginningButtonRef?.current?.focus()
    }

    if (showControls || hasPlaybackEnded) {
      setEnableButtonMovement(true)
    }
  }, [showControls, hasPlaybackEnded, dispatch])

  useEffect(() => {
    adIsPlaying && setShowControls(false)
  }, [adIsPlaying])

  useEffect(() => {
    document.addEventListener('keydown', videoControls)

    return () => document.removeEventListener('keydown', videoControls)
  }, [videoControls])

  useEffect(() => {
    document.addEventListener('click', showControlsOnClick)

    return () => document.removeEventListener('click', showControlsOnClick)
  }, [hidePlayerControls, isPlaying, showControls, showControlsOnClick])

  useEffect(() => {
    document.addEventListener('keydown', closePlayerOnBackButton, true)

    return () => document.removeEventListener('keydown', closePlayerOnBackButton, true)
  })

  return (
    <div className={classNames('player-controls', showControls && 'gradient-overlay')}>
      {showControls && (
        <>
          <OverlayWrapper
            videoConfig={videoConfig}
            playFromBeginningButtonRef={playFromBeginningButtonRef}
            playFromBeginning={startPlaybackFromBeginning}
            showModal={showModal}
            setShowModal={setShowModal}
            play={play}
            pause={pause}
            isLive={player.isLive()}
          />
          <div className="player-controls-container">
            <div className="player-controls-bottom-section">
              <div className="player-controls-button">
                <PrimaryPlayerButton
                  onClick={playPauseVideo}
                  className={playerButtons.PLAY_BUTTON}
                  buttonRef={playPauseButtonRef}
                  id={playerButtons.PLAY_BUTTON}
                  disabledMovement={!enableButtonMovement}
                >
                  {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </PrimaryPlayerButton>
              </div>
              <PlayerProgressBar
                duration={duration}
                currentTime={currentTime}
                isProgressTooltipVisible={isProgressTooltipVisible}
                progressRef={progressRef}
                pause={pause}
                hidePlayerControls={hidePlayerControls}
                setProgressTooltipVisible={setProgressTooltipVisible}
                playFromPosition={playFromPosition}
                playPauseButtonRef={playPauseButtonRef}
                isLive={player.isLive()}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
