export const SKELETON_CARD_TYPES = ['category', 'movie', 'poster'] as const
export const SKELETON_CARD_POSITIONS = ['horizontal', 'vertical'] as const
export const SKELETON_IMAGE_TYPES = ['hero', 'title', 'poster'] as const

export const posterCardsPerRow = 2
export const railRowsHomePage = 4
export const horizontalCardsDetailsPage = 5
export const horizontalCardsPerRow = 6
export const verticalCardsPerRow = 6
export const categoryCardsPerRow = 7
