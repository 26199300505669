import { AdvertisingConfig, PlayerConfig } from 'bitmovin-player'

const adBreaks = [
  {
    id: 0,
    tag: {
      type: 'vast',
      url: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',
    },
    position: 'pre',
    replaceContentDuration: 0,
  },
]

export const advertising: AdvertisingConfig = { adBreaks }

const bufferLevels = {
  forwardduration: 30,
  backwardduration: 10,
}

export const bufferConfig = {
  video: bufferLevels,
  audio: bufferLevels,
}

export const defaultPlayerConfig: PlayerConfig = {
  key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY || '',
  playback: {
    autoplay: true,
  },
  buffer: bufferConfig,
  ui: false,
  advertising: {
    adBreaks: adBreaks,
  },
  tweaks: {
    file_protocol: true,
    app_id: 'com.velvet-tv.app',
    BACKWARD_BUFFER_PURGE_INTERVAL: 10,
    DWORD_BASE_MEDIA_DECODE_TIMESTAMPS: true,
  },
  cast: {
    enable: false,
  },
  analytics: {
    key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY_2CODERS,
    videoId: 'VIDEO_ID',
    title: 'VIDEO_TITLE',
    player: 'Bitmovin player',
    config: {
      enabled: true,
      cookiesEnabled: false,
      origin: 'com.velvet-tv.app',
    },
  },
}

export const NPAW_analytics = {
  accountCode: process.env.REACT_APP_NPAW_ANALYTICS_ACCOUNT_CODE,
}

const recommendations = [
  {
    title: 'Recommendation 1: The best video ever',
    url: '',
    thumbnail: 'http://placehold.it/300x300',
    duration: 10.4,
  },
  {
    title: 'Recommendation 2: The second best video',
    url: '',
    thumbnail: 'http://placehold.it/300x300',
    duration: 64,
  },
  {
    title: 'Recommendation 3: The third best video of all time',
    url: 'http://mymovieapp.com/movie6',
    thumbnail: 'http://placehold.it/300x300',
    duration: 195,
  },
]

export const defaultSourceConfig = {
  dash: 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd',
  hls: 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
  smooth: 'https://test.playready.microsoft.com/smoothstreaming/SSWSS720H264/SuperSpeedway_720.ism/manifest',
  poster: 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/poster.jpg',
  progressive:
    'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4',
  recommendations,
}

export const adBreakPosition = {
  pre: 'pre',
  mid: 'mid',
  post: 'post',
}

export const playerButtons = {
  PLAY_BUTTON: 'play-button',
  AUDIO_AND_SUBTITLES_BUTTON: 'audioAndSubtitles-button',
}
