import { Menu } from "@interfaces/index"
import { MenuAction } from "@reducers/menuReducer"
import axios from "axios"
import { Dispatch } from "react"

export const SET_OPEN_MENU = 'SET_OPEN_MENU'

export const setOpenMenu = (openMenu: boolean) => ({
  type: SET_OPEN_MENU,
  payload: {
    openMenu,
  },
})

export const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST' as const
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS' as const
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE' as const


export const fetchMenuRequest = () => ({ type: FETCH_MENU_REQUEST })

export const fetchMenuSuccess = (menu: Menu) => ({
  type: FETCH_MENU_SUCCESS,
  payload: menu,
})

export const fetchMenuFailure = (error: string) => ({
  type: FETCH_MENU_FAILURE,
  payload: error,
})

export const fetchMenu = (language: string) => {
  return (dispatch: Dispatch<MenuAction>) => {
    dispatch(fetchMenuRequest())
    axios
      .get(process.env.REACT_APP_BASE_URL + `/parsed/${language}/menu?platform=ctv`)
      .then((res) => {
        const menu = res.data
        dispatch(fetchMenuSuccess(menu))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(fetchMenuFailure(errorMsg))
      })
  }
}
