import React from 'react'
import '@pages/Skeleton/DetailsPageSkeleton/DetailsSkeleton.scss'
import HeroSkeleton from '@components/Skeleton/HeroSkeleton/HeroSkeleton'
import TabsSkeleton from '@components/Skeleton/TabsSkeleton/TabsSkeleton'
import SeasonTabsSkeleton from '@components/Skeleton/SeasonTabsSkeleton/SeasonTabsSkeleton'
import RailSkeleton from '@components/Skeleton/RailSkeleton/RailSkeleton'
import { horizontalCardsDetailsPage } from '@constants/skeleton.const'

const SeriesDetailsSkeleton = () => {
  return (
    <div className="details-skeleton-wrapper series-skeleton">
      <HeroSkeleton page="details" />
      <TabsSkeleton />
      <SeasonTabsSkeleton />
      <RailSkeleton itemsType="movie" itemsPosition="horizontal" itemsCount={horizontalCardsDetailsPage} />
    </div>
  )
}

export default SeriesDetailsSkeleton
