import { Tick } from '@assets/index'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import React, { RefObject, useCallback } from 'react'
import './LanguageButton.scss'
import { useDispatch } from 'react-redux'
import { fetchVocabulary } from '@actions/vocabularyActions'
import { getLanguage } from '@utils/vocab'
import { useNavigate } from 'react-router-dom'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import { fetchMenu } from '@actions/menuActions'

interface LanguageButtonProps {
  language: string
  buttonText: string
  buttonRef: RefObject<HTMLDivElement>
}

const LanguageButton = ({ language, buttonText, buttonRef }: LanguageButtonProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const vocabularyFromLS = JSON.parse(localStorage.getItem(userObject?.uid) as string)

  const handleOnSelect = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      e.preventDefault()
      onEnterKeyPressAndClick(e, () => {
        localStorage.setItem(`${userObject?.uid}`, JSON.stringify(language))
        const lang = getLanguage()
        dispatch(fetchVocabulary(lang))
        dispatch(fetchMenu(lang))
        buttonRef && buttonRef?.current?.focus()
        navigate(-1)
      })
    },
    [buttonRef, dispatch, language, navigate, userObject?.uid],
  )

  return (
    <div
      id={language}
      className={'lang-button focusable'}
      tabIndex={-1}
      onKeyDown={handleOnSelect}
      onClick={handleOnSelect}
      ref={buttonRef}
    >
      <Text tag={TextTag.P} className={`innerText ${vocabularyFromLS === language && 'active'}`}>
        {vocabularyFromLS === language && <Tick />}
        <Text tag={TextTag.SPAN} className="language">
          {buttonText}
        </Text>
      </Text>
    </div>
  )
}

export default LanguageButton
