import {
  FetchConfigRequest,
  FetchConfigFailure,
  FetchConfigSuccess,
  FetchConfigInit,
} from '@actions-types/configActionTypes'
import { FETCH_CONFIG_FAILURE, FETCH_CONFIG_SUCCESS, FETCH_CONFIG_REQUEST } from '@actions/configActions'
import { Config } from '@interfaces/index'

const initialState = {
  loadingConfig: 0,
  data: undefined,
  error: '',
}

interface State {
  loadingConfig: number
  data?: Config
  error: string
}

const actionInit = {
  type: '',
  payload: undefined,
}

export type Action = FetchConfigRequest | FetchConfigSuccess | FetchConfigFailure | FetchConfigInit

export const configReducer = (state: State = initialState, action: Action = actionInit): State => {
  switch (action.type) {
    case FETCH_CONFIG_REQUEST:
      return {
        ...state,
        loadingConfig: state.loadingConfig + 1,
      }
    case FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        loadingConfig: state.loadingConfig - 1,
        data: (action as FetchConfigSuccess).payload,
        error: '',
      }
    case FETCH_CONFIG_FAILURE:
      return {
        ...state,
        loadingConfig: 0,
        data: undefined,
        error: (action as FetchConfigFailure).payload,
      }
    default:
      return state
  }
}

export default configReducer
