import React from 'react'
import './DotSpinner.scss'
import spinner from '@assets/Velvet-Spinner.gif'
import Image from '@components/Image/Image'

interface DotSpinerProps {
  isSearch?: boolean
}

const DotSpinner = ({ isSearch }: DotSpinerProps) => {
  return (
    <div className={isSearch ? `dot-spinner-search` : 'dot-spinner'}>
      <Image image={spinner} alt="spinner" />
    </div>
  )
}

export default DotSpinner
