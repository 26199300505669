import React, { useRef } from 'react'
import { Profile } from '@components/Profile/Profile'
import { Logout } from '@components/Profile/Logout/Logout'
import { Legal } from '@components/Profile/Legal/Legal'
import { ProfileInfo } from '@components/Profile/ProfileInfo/ProfileInfo'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { SideMenu } from '@components/SideMenu/SideMenu'
import { DynamicPage } from '@pages/DynamicPage/DynamicPage'
import { Search } from '@pages/Search/Search'
import { BitmovinPlayer } from '@components/BitmovinPlayer/BitmovinPlayer'
import { convertHexToRgb, openMenuOnBackKey } from '@utils/helpers'
import { fetchConfig } from '@actions/configActions'
import ProtectedAuthRoutes from '@components/ProtectedRoutes/ProtectedAuthRoutes'
import ProtectedHomeRoutes from '@components/ProtectedRoutes/ProtectedHomeRoutes'
import MyList from '@pages/MyList/MyList'
import { useTypedSelector } from 'store'
import Category from '@pages/Category/Category'
import Skeleton from '@pages/Skeleton/Skeleton'
import InitialScreen from '@components/InitialScreen/InitialScreen'
import { loginRoutes, profileRoutes, routes } from '@constants/routes.const'
import Register from '@pages/Register/Register'
import { Theme } from '@interfaces/index'
import { Login } from '@pages/Login/Login'
import { ForgotPassword } from '@pages/Login/ForgotPassword/ForgotPassword'
import { PinAndPair } from '@pages/Login/PinAndPair/PinAndPair'
import { AccountError } from '@pages/Login/AccountError/AccountError'
import AppLanguage from '@components/Profile/ProfileInfo/AppLanguage/AppLanguage'
import { fetchVocabulary } from '@actions/vocabularyActions'
import { getLanguage } from '@utils/vocab'
import { initializeSpatialNavigation, scrollNavigate, scrollScreen } from '@utils/scroll.utils'
import PageNotFound from '@pages/PageNotFound/PageNotFound'

function App() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(false)
  const showSkeleton = useTypedSelector((state) => state.skeleton.show)
  const config = useTypedSelector((state) => state.config.data)
  const showMenu = (status: boolean) => setMenu(status)
  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const [toFetchVocab, setToFetchVocab] = useState(true)
  const keyPressed = useRef(false)
  const keyPressedTimeout = useRef(0)
  const showPageNotFound = useTypedSelector((state) => state.pageNotFound.show)

  useEffect(() => {
    initializeSpatialNavigation()
    dispatch(fetchConfig())
  }, [dispatch])

  useEffect(() => {
    if (toFetchVocab && config && userObject) {
      const language = getLanguage()
      dispatch(fetchVocabulary(language))
      setToFetchVocab(false)

      localStorage.setItem(`${userObject?.uid}`, JSON.stringify(language))
    }
  }, [config, dispatch, toFetchVocab, userObject])

  useEffect(() => {
    document.addEventListener('sn:focused', scrollScreen)

    return () => document.removeEventListener('sn:focused', scrollScreen)
  }, [])

  useEffect(() => {
    document.addEventListener('wheel', (e) => scrollNavigate(e, keyPressed, keyPressedTimeout), { passive: false })

    return () => {
      document.removeEventListener('wheel', (e) => scrollNavigate(e, keyPressed, keyPressedTimeout))
    }
  }, [])

  useEffect(() => {
    if (config) {
      const theme: Theme = config.theme

      for (let color in theme) {
        const colorValue = theme[color]

        if (typeof colorValue !== 'string') {
          if (colorValue.type === 'gradient') {
            const gradient = `linear-gradient(${colorValue.from}, ${colorValue.to})`
            document.documentElement.style.setProperty(`--${color}`, gradient)
            document.documentElement.style.setProperty(`--bgGradientTo`, colorValue.to)
          }
        } else {
          document.documentElement.style.setProperty(`--${color}`, colorValue)
          document.documentElement.style.setProperty(`--${color}-rgb`, convertHexToRgb(colorValue))
        }
      }
    }
  }, [config])

  useEffect(() => {
    if (
      !location.pathname.startsWith(routes.MOVIES + '/') &&
      !location.pathname.startsWith(routes.SERIES + '/') &&
      !showSkeleton
    ) {
      document.addEventListener('keydown', openMenuOnBackKey)
      return () => document.removeEventListener('keydown', openMenuOnBackKey)
    }
  }, [location.pathname, showSkeleton])

  return (
    <div className="App">
      <div className="app-side-menu">
        <SideMenu showMenu={menu} />
      </div>

      <div className="app-content">
        <div className="background"></div>
        {showSkeleton && <Skeleton />}
        <Routes>
          <Route element={<ProtectedAuthRoutes />}>
            <Route path={routes.INITIAL_PAGE} element={<InitialScreen showMenu={showMenu} />} />
            <Route path={routes.LOGIN}>
              <Route index element={<Login showMenu={showMenu} />} />
              <Route path={loginRoutes.FORGOT_PASSWORD} element={<ForgotPassword showMenu={showMenu} />} />
              <Route path={loginRoutes.PIN_AND_PAIR} element={<PinAndPair showMenu={showMenu} />} />
              <Route path={loginRoutes.ACCOUNT_ERROR} element={<AccountError showMenu={showMenu} />} />
            </Route>
            <Route path={routes.REGISTER} element={<Register showMenu={showMenu} />} />
          </Route>
          <Route element={<ProtectedHomeRoutes />}>
            <Route path={routes.SEARCH} element={<Search showMenu={showMenu} />} />
            <Route path={routes.MY_LIST} element={<MyList showMenu={showMenu} />} />
            <Route path={`${routes.CATEGORY}/:chosenCategory`} element={<Category showMenu={showMenu} />} />
            <Route path={routes.PROFILE}>
              <Route index element={<Profile showMenu={showMenu} />} />
              <Route path={profileRoutes.PROFILE_INFO}>
                <Route index element={<ProfileInfo showMenu={showMenu} />} />
                <Route path={profileRoutes.EDIT_LANG} element={<AppLanguage showMenu={showMenu} />} />
              </Route>
              <Route path={profileRoutes.LEGAL} element={<Legal showMenu={showMenu} />} />
              <Route path={profileRoutes.LOGOUT} element={<Logout showMenu={showMenu} />} />
            </Route>
            <Route path="/*" element={<DynamicPage showMenu={showMenu} />} />
            {showPageNotFound && (
              <Route path={`${routes.PAGE_NOT_FOUND}`} element={<PageNotFound showMenu={showMenu} />} />
            )}
            <Route path={`${routes.PLAYER}/:slug`} element={<BitmovinPlayer showMenu={showMenu} />} />

            {/* will be commented until further notice - currently not using */}
            {/* <Route path="/:contentCategory/:id/:option" element={<RentOrBuy showMenu={showMenu} />} /> */}
            {/* <Route
              path="/:contentCategory/:id/more-purchase-options"
              element={<MorePurchaseOptions showMenu={showMenu} />}
            /> */}
          </Route>
        </Routes>
      </div>
    </div>
  )
}

export default App
