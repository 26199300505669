import { NavLink, useLocation } from 'react-router-dom'
import type { menuItem } from '@components/SideMenu/OpenSideMenu/OpenSideMenu'
import './ClosedSideMenu.scss'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { focusableRoutes, routes } from '@constants/routes.const'
import { setMenuIcon } from '../menuIcons'
import Image from '@components/Image/Image'

interface closedSideMenuPros {
  closedMenuAnimation: boolean
  menuItems?: menuItem[]
}

export const ClosedSideMenu = ({ closedMenuAnimation, menuItems }: closedSideMenuPros) => {
  const [userName, setUserName] = useState<string | null>('')
  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const userNameFromLS = userObject?.displayName
  const userPhotoFromLS = userObject?.photoURL
  const userEmailFromLS = userObject?.email
  const location = useLocation()

  useEffect(() => {
    if (userNameFromLS !== null) {
      setUserName(userNameFromLS)
    }
  }, [userNameFromLS])

  return (
    <motion.div
      className="closed-side-menu"
      initial={closedMenuAnimation && { x: '-20%' }}
      animate={
        closedMenuAnimation && {
          x: -182,
          backgroundColor: 'rgba(14, 4, 55, 0)',
        }
      }
      transition={{ type: 'spring', bounce: 0, duration: 0.8 }}
    >
      <div className="closed-side-menu-items">
        <NavLink
          className={({ isActive }) =>
            `${
              isActive && location.pathname === routes.PROFILE ? 'active ' : ''
            }closed-side-menu-profile-image-container`
          }
          to={routes.PROFILE}
        >
          <div className="closed-side-menu-profile-image">
            {userPhotoFromLS ? (
              <Image image={userPhotoFromLS} alt="User" width="58" height="58" />
            ) : (
              <div className="closed-side-menu-profile-image-letter">
                {userName?.charAt(0) || userEmailFromLS?.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
        </NavLink>
        <div className="closed-side-menu-icons-container">
          {menuItems?.map((menuItem, index) => {
            return (
              <NavLink
                to={`${menuItem.path}`}
                id={`${focusableRoutes.MENU}-item-${index}`}
                className={({ isActive }) => (isActive && location.pathname === menuItem.path ? 'active' : '')}
                key={menuItem.text}
              >
                {setMenuIcon(menuItem.icon)}
                <div className="side-menu-border"></div>
              </NavLink>
            )
          })}
        </div>
      </div>
    </motion.div>
  )
}
