import { FetchPageRequest, FetchPageFailure, FetchPageSuccess, FetchPageInit } from '@actions-types/pageActionTypes'
import { FETCH_PAGE_FAILURE, FETCH_PAGE_SUCCESS, FETCH_PAGE_REQUEST, RESET_PAGE } from '@actions/pageActions'

const initialState = {
  loadingPage: 0,
  data: undefined,
  error: '',
}

interface State {
  loadingPage: number
  data?: any
  error: string
}

const actionInit = {
  type: '',
  payload: undefined,
}

export type Action = FetchPageRequest | FetchPageSuccess | FetchPageFailure | FetchPageInit

export const pageReducer = (state: State = initialState, action: Action = actionInit): State => {
  switch (action.type) {
    case FETCH_PAGE_REQUEST:
      return {
        ...state,
        loadingPage: state.loadingPage + 1,
        data: initialState.data,
      }
    case FETCH_PAGE_SUCCESS:
      return {
        ...state,
        loadingPage: state.loadingPage - 1,
        data: (action as FetchPageSuccess).payload,
        error: '',
      }
    case FETCH_PAGE_FAILURE:
      return {
        ...state,
        loadingPage: 0,
        data: null,
        error: (action as FetchPageFailure).payload,
      }
    case RESET_PAGE:
      return {
        ...state,
        loadingPage: 0,
        data: {},
        error: (action as FetchPageFailure).payload,
      }
    default:
      return state
  }
}

export default pageReducer
